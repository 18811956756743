import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
  term?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotifcationPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'Notification', id: string, numberOfMessages?: number | null, successfulMessages?: number | null, failedMessages?: number | null, createdAt: string, updatedAt: string, en?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string } | null } | null, de?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string } | null } | null }> } };


export const NotificationsDocument = gql`
    query Notifications($offset: Int!, $limit: Int!, $term: String) {
  notifications(limit: $limit, offset: $offset, term: $term) {
    docs {
      id
      numberOfMessages
      successfulMessages
      failedMessages
      en {
        title
        body
        mainEvent {
          id
          name
        }
      }
      de {
        title
        body
        mainEvent {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;