import { Box, Container, Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import Navigation from './Navigation';

const Layout: FC = ({ children }) => (
  <Container
    h="100vh"
    maxH="none"
    w="full"
    maxW="none"
    p="5"
    bg="gray.100"
  >
    <Flex
      gap="5"
      height="full"
    >
      <Navigation />
      <Box
        flexGrow="1"
        bg="white"
        padding="6"
        borderRadius="lg"
        shadow="md"
        overflowY="scroll"
      >
        {children}
      </Box>
    </Flex>
  </Container>
);

export default Layout;
