import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PlaceFragmentDoc } from '../../fragments/generated/place.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePlaceMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description?: Types.InputMaybe<Types.Scalars['String']>;
  locale: Types.Scalars['String'];
  image?: Types.InputMaybe<Types.Scalars['ID']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
  coordinates?: Types.InputMaybe<Types.CoordinateInput>;
}>;


export type CreatePlaceMutation = { __typename?: 'Mutation', createPlace: { __typename?: 'Place', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null, coordinates?: { __typename?: 'Coordinate', lat?: number | null, lng?: number | null, name?: string | null } | null } };


export const CreatePlaceDocument = gql`
    mutation CreatePlace($name: String!, $description: String, $locale: String!, $image: ID, $status: StatusTypes, $coordinates: CoordinateInput) {
  createPlace(
    name: $name
    description: $description
    locale: $locale
    image: $image
    status: $status
    coordinates: $coordinates
  ) {
    ...place
  }
}
    ${PlaceFragmentDoc}`;
export type CreatePlaceMutationFn = Apollo.MutationFunction<CreatePlaceMutation, CreatePlaceMutationVariables>;

/**
 * __useCreatePlaceMutation__
 *
 * To run a mutation, you first call `useCreatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaceMutation, { data, loading, error }] = useCreatePlaceMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      image: // value for 'image'
 *      status: // value for 'status'
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useCreatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlaceMutation, CreatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlaceMutation, CreatePlaceMutationVariables>(CreatePlaceDocument, options);
      }
export type CreatePlaceMutationHookResult = ReturnType<typeof useCreatePlaceMutation>;
export type CreatePlaceMutationResult = Apollo.MutationResult<CreatePlaceMutation>;
export type CreatePlaceMutationOptions = Apollo.BaseMutationOptions<CreatePlaceMutation, CreatePlaceMutationVariables>;