import { Badge, Image, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { EventsQuery } from '../../../graphql/events/generated/eventsQuery.generated';

const EventsColumn: FC<{ item: EventsQuery['events']['docs'][number] }> = ({ item }) => (
  <>
    <Td>
      {item.image && (
        <Image
          src={item.image.formats.xs.url}
          alt={item.image.altText ? item.image.altText : undefined}
        />
      )}
    </Td>
    <Td>{item.name}</Td>
    <Td>{item.start ? format(new Date(item.start), 'd.M.yyyy HH:mm') : ''}</Td>
    <Td>
      <Badge
        colorScheme={item.status === 'PUBLISHED' ? 'green' : 'yellow'}
      >
        {item.status === 'PUBLISHED' ? 'Veröffentlicht' : 'Entwurf'}
      </Badge>
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default EventsColumn;
