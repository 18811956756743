import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { ArtistFragmentDoc } from '../../fragments/generated/artist.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ArtistsQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  locale: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  mainEvent?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ArtistsQuery = { __typename?: 'Query', artists: { __typename?: 'ArtistPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'Artist', id: string, name: string, description?: string | null, createdAt: string, locale: string, updatedAt: string, status: Types.StatusTypes, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null }> } };


export const ArtistsDocument = gql`
    query Artists($term: String!, $status: StatusTypes, $locale: String!, $limit: Int!, $offset: Int!, $mainEvent: String) {
  artists(
    term: $term
    status: $status
    locale: $locale
    limit: $limit
    offset: $offset
    mainEvent: $mainEvent
  ) {
    docs {
      ...artist
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    ${ArtistFragmentDoc}`;

/**
 * __useArtistsQuery__
 *
 * To run a query within a React component, call `useArtistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtistsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      mainEvent: // value for 'mainEvent'
 *   },
 * });
 */
export function useArtistsQuery(baseOptions: Apollo.QueryHookOptions<ArtistsQuery, ArtistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArtistsQuery, ArtistsQueryVariables>(ArtistsDocument, options);
      }
export function useArtistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArtistsQuery, ArtistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArtistsQuery, ArtistsQueryVariables>(ArtistsDocument, options);
        }
export type ArtistsQueryHookResult = ReturnType<typeof useArtistsQuery>;
export type ArtistsLazyQueryHookResult = ReturnType<typeof useArtistsLazyQuery>;
export type ArtistsQueryResult = Apollo.QueryResult<ArtistsQuery, ArtistsQueryVariables>;