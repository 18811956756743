import {
  Box, Flex, Heading, Icon, Image, Spinner, Text,
} from '@chakra-ui/react';
import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useDebouncedValue } from 'rooks';
import { GoSearch } from 'react-icons/go';
import { MediasQueryVariables, useMediasQuery } from '../../graphql/media/generated/mediasQuery.generated';
import TextInput from '../TextInput';
import { Media } from '../../types/graphql.generated';

interface MediaSelectorListProps {
  locale: string,
  onSelect: (media: Omit<Media, 'width' | 'height'>) => void,
  selected?: Omit<Media, 'width' | 'height'> | null,
}

const MediaSelectorList: FC<MediaSelectorListProps> = ({ locale, selected, onSelect }) => {
  const [searchString, setSearchString] = useState<string>('');

  const [debouncedSearchString] = useDebouncedValue(searchString, 300);

  const [variables, setVariables] = useState<MediasQueryVariables>({
    term: '',
    limit: 20,
    offset: 0,
    locale,
  });

  const onVariablesChange = (params: Partial<MediasQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { data, loading } = useMediasQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (debouncedSearchString !== null) {
      onVariablesChange({ term: debouncedSearchString });
    }
  }, [debouncedSearchString]);

  const items = useMemo(() => {
    if (data && data.medias.docs.length > 0) {
      if (selected) {
        return [
          selected,
          ...data.medias.docs.filter((item) => item.id !== selected.id),
        ];
      }
      return data.medias.docs;
    }
    return [];
  }, [data, selected]);

  return (
    <Flex
      flexDir="column"
      gap="20px"
    >
      <Box
        w="230px"
      >
        <TextInput
          placeholder="Suche..."
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          icon={<Icon as={GoSearch} />}
        >
          Suche:
        </TextInput>
      </Box>
      <Flex
        w="full"
        gap="20px"
        flexWrap="wrap"
      >
        {items.map((media) => (
          <Flex
            key={media.id}
            flexDir="column"
            gap="20px"
            maxW="200px"
            align="center"
            cursor="pointer"
            position="relative"
          >
            <Image
              src={media.formats.m.url}
              alt={media.altText || ''}
            />
            <Heading
              size="sm"
            >
              {media.name}
            </Heading>
            {(selected && selected.id === media.id) ? (
              <Flex
                position="absolute"
                width="full"
                height="full"
                background="blackAlpha.600"
                transition="all"
                transitionDuration=".4s"
                align="center"
                justify="center"
              >
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  transition="all"
                  transitionDuration=".4s"
                >
                  Ausgewählt
                </Text>
              </Flex>
            ) : (
              <Flex
                position="absolute"
                width="full"
                height="full"
                background="transparent"
                transition="all"
                transitionDuration=".4s"
                align="center"
                justify="center"
                onClick={() => onSelect(media)}
                _hover={{
                  background: 'blackAlpha.600',
                  p: {
                    opacity: 1,
                  },
                }}
              >
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  transition="all"
                  transitionDuration=".4s"
                  opacity="0"
                >
                  Auswählen
                </Text>
              </Flex>
            )}

          </Flex>
        ))}
      </Flex>
      {loading && (
        <Flex
          w="100%"
          justify="center"
          mt="10"
        >
          <Spinner
            thickness="4px"
            color="teal"
            emptyColor="gray.200"
            size="xl"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default MediaSelectorList;
