import { Button, Heading, useToast } from '@chakra-ui/react';
import { pick } from 'lodash';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import NotificationForm from '../../components/notifications/NotificationForm';
import { CreateNotificationMutationVariables } from '../../graphql/notifications/generated/createNotification.generated';
import { useScheduledNotificationQuery } from '../../graphql/notifications/generated/scheduledNotification.generated';
import { useUpdateScheduledNotificationMutation } from '../../graphql/notifications/generated/updateScheduledNotificationMutation.generated';

const UpdateNotification: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [updateNotification, { loading }] = useUpdateScheduledNotificationMutation();
  const { id } = useParams() as { id: string };

  const { data } = useScheduledNotificationQuery({
    variables: { id },
  });

  const onSubmit = async (formData: CreateNotificationMutationVariables) => {
    try {
      const params = pick(formData, ['date', 'en', 'de']);
      if (params.en) {
        params.en = pick(params.en, ['title', 'body', 'mainEvent']);
      }
      if (params.de) {
        params.de = pick(params.de, ['title', 'body', 'mainEvent']);
      }
      await updateNotification({
        variables: {
          id,
          input: {
            ...params,
            date: formData.scheduleForDate,
          },
        },
      });
      toast({
        title: 'Benachrichtigung wurde geändert',
        description: 'Benachrichtigung wurde geändert.',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/notifications');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Benachrichtigung ändern</Heading>
      <Button
        as={Link}
        to="/notifications"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {data && (
        <NotificationForm
          loading={loading}
          onSubmit={onSubmit}
          defaultValues={{
            ...pick(data.scheduledNotification, 'date', 'en', 'de') as any,
            scheduleForDate: data.scheduledNotification.date,
          }}
        />
      )}
    </Layout>
  );
};

export default UpdateNotification;
