import { Badge, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormsQuery } from '../../../graphql/forms/generated/formsQuery.generated';

const FormColumn: FC<{ item: FormsQuery['forms']['docs'][number] }> = ({ item }) => (
  <>
    <Td>{item.name}</Td>
    <Td>
      <Badge
        colorScheme={item.status === 'PUBLISHED' ? 'green' : 'yellow'}
      >
        {item.status === 'PUBLISHED' ? 'Veröffentlicht' : 'Entwurf'}
      </Badge>
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default FormColumn;
