import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMainEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
  logo?: Types.InputMaybe<Types.Scalars['ID']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
  content?: Types.InputMaybe<Types.Scalars['JSON']>;
  showScheduleLink?: Types.InputMaybe<Types.Scalars['Boolean']>;
  disableInfoAndProgram?: Types.InputMaybe<Types.Scalars['Boolean']>;
  mainPageId?: Types.InputMaybe<Types.Scalars['String']>;
  position?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateMainEventMutation = { __typename?: 'Mutation', updateMainEvent: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } };


export const UpdateMainEventDocument = gql`
    mutation UpdateMainEvent($id: ID!, $name: String, $description: String, $locale: String, $logo: ID, $status: StatusTypes, $content: JSON, $showScheduleLink: Boolean, $disableInfoAndProgram: Boolean, $mainPageId: String, $position: Int) {
  updateMainEvent(
    id: $id
    input: {name: $name, description: $description, locale: $locale, logo: $logo, status: $status, content: $content, showScheduleLink: $showScheduleLink, disableInfoAndProgram: $disableInfoAndProgram, mainPageId: $mainPageId, position: $position}
  ) {
    ...mainEvent
  }
}
    ${MainEventFragmentDoc}`;
export type UpdateMainEventMutationFn = Apollo.MutationFunction<UpdateMainEventMutation, UpdateMainEventMutationVariables>;

/**
 * __useUpdateMainEventMutation__
 *
 * To run a mutation, you first call `useUpdateMainEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMainEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMainEventMutation, { data, loading, error }] = useUpdateMainEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      logo: // value for 'logo'
 *      status: // value for 'status'
 *      content: // value for 'content'
 *      showScheduleLink: // value for 'showScheduleLink'
 *      disableInfoAndProgram: // value for 'disableInfoAndProgram'
 *      mainPageId: // value for 'mainPageId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useUpdateMainEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMainEventMutation, UpdateMainEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMainEventMutation, UpdateMainEventMutationVariables>(UpdateMainEventDocument, options);
      }
export type UpdateMainEventMutationHookResult = ReturnType<typeof useUpdateMainEventMutation>;
export type UpdateMainEventMutationResult = Apollo.MutationResult<UpdateMainEventMutation>;
export type UpdateMainEventMutationOptions = Apollo.BaseMutationOptions<UpdateMainEventMutation, UpdateMainEventMutationVariables>;