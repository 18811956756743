import {
  Box, Button, Image, Text, useDisclosure,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Media } from '../types/graphql.generated';
import MediaSelectorModal from './media/MediaSelectorModal';

interface MediaInputProps {
  onChange?: (media: Omit<Media, 'height' | 'width'>) => void,
  value?: Omit<Media, 'height' | 'width'>,
  isInvalid?: boolean,
  locale?: string,
}

const MediaInput: FC<MediaInputProps> = ({
  children, value, onChange, isInvalid = true, locale = 'de',
}) => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  const onSelect = (media: Omit<Media, 'width' | 'height'>) => {
    if (onChange) {
      onChange(media);
    }
  };

  return (
    <Box
      borderColor={isInvalid ? 'red.500' : 'transparent'}
      borderWidth="2px"
      borderRadius="lg"
      p={isInvalid ? '3' : '0'}
    >
      <Text
        w="full"
        fontWeight="bold"
        mb="8px"
      >
        {children}
      </Text>
      {value && (
        <Box>
          <Image
            alt={value.altText || ''}
            src={value.formats.s.url}
          />
          <Text
            my="10px"
            fontSize="lg"
            fontWeight="bold"
          >
            {value.name}
          </Text>
        </Box>
      )}
      <Button
        colorScheme="teal"
        onClick={onOpen}
      >
        Bild/Video
        {value ? ' ändern' : ' auswählen'}
      </Button>
      <MediaSelectorModal
        isOpen={isOpen}
        locale={locale}
        onClose={onClose}
        onSelect={onSelect}
      />
    </Box>
  );
};

export default MediaInput;
