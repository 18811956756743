import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyMainEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  locale: Types.Scalars['String'];
}>;


export type CopyMainEventMutation = { __typename?: 'Mutation', copyMainEvent: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } };


export const CopyMainEventDocument = gql`
    mutation CopyMainEvent($id: ID!, $locale: String!) {
  copyMainEvent(id: $id, locale: $locale) {
    ...mainEvent
  }
}
    ${MainEventFragmentDoc}`;
export type CopyMainEventMutationFn = Apollo.MutationFunction<CopyMainEventMutation, CopyMainEventMutationVariables>;

/**
 * __useCopyMainEventMutation__
 *
 * To run a mutation, you first call `useCopyMainEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyMainEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyMainEventMutation, { data, loading, error }] = useCopyMainEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCopyMainEventMutation(baseOptions?: Apollo.MutationHookOptions<CopyMainEventMutation, CopyMainEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyMainEventMutation, CopyMainEventMutationVariables>(CopyMainEventDocument, options);
      }
export type CopyMainEventMutationHookResult = ReturnType<typeof useCopyMainEventMutation>;
export type CopyMainEventMutationResult = Apollo.MutationResult<CopyMainEventMutation>;
export type CopyMainEventMutationOptions = Apollo.BaseMutationOptions<CopyMainEventMutation, CopyMainEventMutationVariables>;