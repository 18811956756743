import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import ArtistForm from '../../components/artists/ArtistForm';
import Layout from '../../components/Layout';
import { CreateArtistMutationVariables, useCreateArtistMutation } from '../../graphql/artists/generated/createArtistMutation.generated';

const CreateArtist: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createArtist, { loading }] = useCreateArtistMutation();

  const onSubmit = async (data: CreateArtistMutationVariables) => {
    try {
      await createArtist({
        variables: data,
      });
      toast({
        title: 'Künster:in erstellt',
        description: 'der/die Künster:in wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/artists');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Künstler:in erstellen</Heading>
      <Button
        as={Link}
        to="/artists"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <ArtistForm
        loading={loading}
        onSubmit={onSubmit}
        withPublish
      />
    </Layout>
  );
};

export default CreateArtist;
