import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc } from '../../fragments/generated/media.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMediaMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteMediaMutation = { __typename?: 'Mutation', deleteMedia: { __typename?: 'Media', id: string, name: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, locale: string, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', width: number, url: string }, s: { __typename?: 'MediaFormat', width: number, url: string }, m: { __typename?: 'MediaFormat', width: number, url: string }, l: { __typename?: 'MediaFormat', width: number, url: string }, xl: { __typename?: 'MediaFormat', width: number, url: string } } } };


export const DeleteMediaDocument = gql`
    mutation DeleteMedia($id: ID!) {
  deleteMedia(id: $id) {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export type DeleteMediaMutationFn = Apollo.MutationFunction<DeleteMediaMutation, DeleteMediaMutationVariables>;

/**
 * __useDeleteMediaMutation__
 *
 * To run a mutation, you first call `useDeleteMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMediaMutation, { data, loading, error }] = useDeleteMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMediaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMediaMutation, DeleteMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMediaMutation, DeleteMediaMutationVariables>(DeleteMediaDocument, options);
      }
export type DeleteMediaMutationHookResult = ReturnType<typeof useDeleteMediaMutation>;
export type DeleteMediaMutationResult = Apollo.MutationResult<DeleteMediaMutation>;
export type DeleteMediaMutationOptions = Apollo.BaseMutationOptions<DeleteMediaMutation, DeleteMediaMutationVariables>;