import {
  Button, FormControl, FormLabel, Heading, Switch, Th, useToast,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import MainEventForm from '../../components/main-events/MainEventForm';
import InnerTable from '../../components/table/InnerTable';
import { CreateMainEventMutationVariables } from '../../graphql/mainEvents/generated/createMainEventMutation.generated';
import { useMainEventQuery } from '../../graphql/mainEvents/generated/mainEventQuery.generated';
import { useUpdateMainEventMutation } from '../../graphql/mainEvents/generated/updateMainEventMutation.generated';
import { useDeletePageMutation } from '../../graphql/pages/generated/deletePageMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdateMainEvent: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updateMainEvent, { loading }] = useUpdateMainEventMutation();
  const [deletePage] = useDeletePageMutation();

  const { data: mainEventResponse, loading: queryLoading, refetch } = useMainEventQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onSubmit = async (data: CreateMainEventMutationVariables) => {
    try {
      await updateMainEvent({
        variables: {
          id,
          ...data,
        },
      });
      toast({
        title: 'Event geändert',
        description: 'Das Event wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/main-events');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updateMainEvent({
        variables: {
          id,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Event geändert',
        description: 'Das Event wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onPageDelete = async (deleteId: string) => {
    try {
      await deletePage({
        variables: {
          id: deleteId,
        },
      });
      toast({
        title: 'Seite wurde gelöscht',
        description: 'Die ausgewählte Seite wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Event ändern</Heading>
      <Button
        as={Link}
        to="/main-events"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {mainEventResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={mainEventResponse.mainEvent.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <MainEventForm
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={mainEventResponse.mainEvent}
          />
          <Heading size="lg" mt="40px">Dynamische Seiten</Heading>
          <Button
            as={Link}
            to={`/main-events/${id}/${mainEventResponse.mainEvent.locale}/pages/create`}
            leftIcon={<GoPlus />}
            colorScheme="teal"
            mt="20px"
          >
            Erstellen
          </Button>
          <InnerTable
            items={mainEventResponse.mainEvent.pages}
            loading={queryLoading}
            onDelete={onPageDelete}
            pathPrefix={`main-events/${id}/${mainEventResponse.mainEvent.locale}/pages`}
            tableHead={(
              <>
                <Th>Name</Th>
                <Th>Status</Th>
                <Th>Erstellt</Th>
                <Th>Geändert</Th>
              </>
            )}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdateMainEvent;
