import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledNotificationQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type ScheduledNotificationQuery = { __typename?: 'Query', scheduledNotification: { __typename?: 'ScheduledNotification', id: string, date?: string | null, createdAt: string, updatedAt: string, en?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } | null } | null, de?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } | null } | null } };


export const ScheduledNotificationDocument = gql`
    query ScheduledNotification($id: ID!) {
  scheduledNotification(id: $id) {
    id
    date
    en {
      title
      body
      mainEvent {
        ...mainEvent
      }
    }
    de {
      title
      body
      mainEvent {
        ...mainEvent
      }
    }
    createdAt
    updatedAt
  }
}
    ${MainEventFragmentDoc}`;

/**
 * __useScheduledNotificationQuery__
 *
 * To run a query within a React component, call `useScheduledNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduledNotificationQuery(baseOptions: Apollo.QueryHookOptions<ScheduledNotificationQuery, ScheduledNotificationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledNotificationQuery, ScheduledNotificationQueryVariables>(ScheduledNotificationDocument, options);
      }
export function useScheduledNotificationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledNotificationQuery, ScheduledNotificationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledNotificationQuery, ScheduledNotificationQueryVariables>(ScheduledNotificationDocument, options);
        }
export type ScheduledNotificationQueryHookResult = ReturnType<typeof useScheduledNotificationQuery>;
export type ScheduledNotificationLazyQueryHookResult = ReturnType<typeof useScheduledNotificationLazyQuery>;
export type ScheduledNotificationQueryResult = Apollo.QueryResult<ScheduledNotificationQuery, ScheduledNotificationQueryVariables>;