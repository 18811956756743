import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteMainEventMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteMainEventMutation = { __typename?: 'Mutation', deleteMainEvent: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } };


export const DeleteMainEventDocument = gql`
    mutation DeleteMainEvent($id: ID!) {
  deleteMainEvent(id: $id) {
    ...mainEvent
  }
}
    ${MainEventFragmentDoc}`;
export type DeleteMainEventMutationFn = Apollo.MutationFunction<DeleteMainEventMutation, DeleteMainEventMutationVariables>;

/**
 * __useDeleteMainEventMutation__
 *
 * To run a mutation, you first call `useDeleteMainEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMainEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMainEventMutation, { data, loading, error }] = useDeleteMainEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMainEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMainEventMutation, DeleteMainEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMainEventMutation, DeleteMainEventMutationVariables>(DeleteMainEventDocument, options);
      }
export type DeleteMainEventMutationHookResult = ReturnType<typeof useDeleteMainEventMutation>;
export type DeleteMainEventMutationResult = Apollo.MutationResult<DeleteMainEventMutation>;
export type DeleteMainEventMutationOptions = Apollo.BaseMutationOptions<DeleteMainEventMutation, DeleteMainEventMutationVariables>;