import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc } from '../../fragments/generated/media.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMediaMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  altText?: Types.InputMaybe<Types.Scalars['String']>;
  caption?: Types.InputMaybe<Types.Scalars['String']>;
  file: Types.Scalars['Upload'];
  locale: Types.Scalars['String'];
}>;


export type CreateMediaMutation = { __typename?: 'Mutation', createMedia: { __typename?: 'Media', id: string, name: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, locale: string, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', width: number, url: string }, s: { __typename?: 'MediaFormat', width: number, url: string }, m: { __typename?: 'MediaFormat', width: number, url: string }, l: { __typename?: 'MediaFormat', width: number, url: string }, xl: { __typename?: 'MediaFormat', width: number, url: string } } } };


export const CreateMediaDocument = gql`
    mutation CreateMedia($name: String!, $altText: String, $caption: String, $file: Upload!, $locale: String!) {
  createMedia(
    name: $name
    locale: $locale
    file: $file
    altText: $altText
    caption: $caption
  ) {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export type CreateMediaMutationFn = Apollo.MutationFunction<CreateMediaMutation, CreateMediaMutationVariables>;

/**
 * __useCreateMediaMutation__
 *
 * To run a mutation, you first call `useCreateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMediaMutation, { data, loading, error }] = useCreateMediaMutation({
 *   variables: {
 *      name: // value for 'name'
 *      altText: // value for 'altText'
 *      caption: // value for 'caption'
 *      file: // value for 'file'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCreateMediaMutation(baseOptions?: Apollo.MutationHookOptions<CreateMediaMutation, CreateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMediaMutation, CreateMediaMutationVariables>(CreateMediaDocument, options);
      }
export type CreateMediaMutationHookResult = ReturnType<typeof useCreateMediaMutation>;
export type CreateMediaMutationResult = Apollo.MutationResult<CreateMediaMutation>;
export type CreateMediaMutationOptions = Apollo.BaseMutationOptions<CreateMediaMutation, CreateMediaMutationVariables>;