import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import Layout from '../../components/Layout';
import MediaForm, { MediaFormSubmit } from '../../components/media/MediaForm';
import { useMediaQuery } from '../../graphql/media/generated/mediaQuery.generated';
import { useUpdateMediaMutation } from '../../graphql/media/generated/updateMediaMutation.generated';

const UpdateMedia: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updateMedia, { loading }] = useUpdateMediaMutation();

  const { data: mediaResponse } = useMediaQuery({
    variables: {
      id,
    },
  });

  const onSubmit = async (data: MediaFormSubmit) => {
    try {
      await updateMedia({
        variables: {
          ...data,
          id,
          file: data.file.length > 0 ? data.file[0] : null,
        },
      });
      toast({
        title: 'Bild/Video geändert',
        description: 'Die ausgewählte Ressource wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/media');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Bilder oder video hochladen</Heading>
      <Button
        as={Link}
        to="/media"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {mediaResponse && (
        <MediaForm
          loading={loading}
          onSubmit={onSubmit}
          defaultValues={mediaResponse.media as any}
        />
      )}
    </Layout>
  );
};

export default UpdateMedia;
