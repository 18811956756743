import {
  Button, FormControl, FormLabel, Heading, Switch, useToast,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArtistForm from '../../components/artists/ArtistForm';
import Layout from '../../components/Layout';
import { useArtistQuery } from '../../graphql/artists/generated/artistQuery.generated';
import { CreateArtistMutationVariables } from '../../graphql/artists/generated/createArtistMutation.generated';
import { useUpdateArtistMutation } from '../../graphql/artists/generated/updateArtistMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdateArtist: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updateArtist, { loading }] = useUpdateArtistMutation();

  const { data: artistResponse } = useArtistQuery({
    variables: {
      id,
    },
  });

  const onSubmit = async (data: CreateArtistMutationVariables) => {
    try {
      await updateArtist({
        variables: {
          id,
          ...data,
        },
      });
      toast({
        title: 'Künster:in geändert',
        description: 'der/die Künster:in wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/artists');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updateArtist({
        variables: {
          id,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Künster:in geändert',
        description: 'der/die Künster:in wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Künstler:in ändern</Heading>
      <Button
        as={Link}
        to="/artists"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {artistResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={artistResponse.artist.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <ArtistForm
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={artistResponse.artist as any}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdateArtist;
