import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ScheduledNotificationsQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int'];
  limit: Types.Scalars['Int'];
  term?: Types.InputMaybe<Types.Scalars['String']>;
  hasIdentifier?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type ScheduledNotificationsQuery = { __typename?: 'Query', scheduledNotifications: { __typename?: 'ScheduledNotifcationPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'ScheduledNotification', id: string, date?: string | null, createdAt: string, updatedAt: string, en?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string } | null } | null, de?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null, mainEvent?: { __typename?: 'MainEvent', id: string, name: string } | null } | null }> } };


export const ScheduledNotificationsDocument = gql`
    query ScheduledNotifications($offset: Int!, $limit: Int!, $term: String, $hasIdentifier: Boolean) {
  scheduledNotifications(
    limit: $limit
    offset: $offset
    term: $term
    hasIdentifier: $hasIdentifier
  ) {
    docs {
      id
      date
      en {
        title
        body
        mainEvent {
          id
          name
        }
      }
      de {
        title
        body
        mainEvent {
          id
          name
        }
      }
      createdAt
      updatedAt
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    `;

/**
 * __useScheduledNotificationsQuery__
 *
 * To run a query within a React component, call `useScheduledNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduledNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduledNotificationsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      term: // value for 'term'
 *      hasIdentifier: // value for 'hasIdentifier'
 *   },
 * });
 */
export function useScheduledNotificationsQuery(baseOptions: Apollo.QueryHookOptions<ScheduledNotificationsQuery, ScheduledNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScheduledNotificationsQuery, ScheduledNotificationsQueryVariables>(ScheduledNotificationsDocument, options);
      }
export function useScheduledNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScheduledNotificationsQuery, ScheduledNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScheduledNotificationsQuery, ScheduledNotificationsQueryVariables>(ScheduledNotificationsDocument, options);
        }
export type ScheduledNotificationsQueryHookResult = ReturnType<typeof useScheduledNotificationsQuery>;
export type ScheduledNotificationsLazyQueryHookResult = ReturnType<typeof useScheduledNotificationsLazyQuery>;
export type ScheduledNotificationsQueryResult = Apollo.QueryResult<ScheduledNotificationsQuery, ScheduledNotificationsQueryVariables>;