import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import PopupForm from '../../components/notifications/PopupForm';
import { CallToSubscribeMutationVariables, useCallToSubscribeMutation } from '../../graphql/notifications/generated/callToSubscribe.generated';

const CreatePopup: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [callToSubscribe, { loading }] = useCallToSubscribeMutation();

  const onSubmit = async (data: CallToSubscribeMutationVariables) => {
    try {
      await callToSubscribe({
        variables: data,
      });
      toast({
        title: 'Benachrichtigung wurde gesendet',
        description: 'Benachrichtigung wurde gesendet, der Status kann in der Übersicht überprüft werden.',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/notifications');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Benachrichtigung senden</Heading>
      <Button
        as={Link}
        to="/notifications"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <PopupForm
        loading={loading}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default CreatePopup;
