import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import MainEventForm from '../../components/main-events/MainEventForm';
import { CreateMainEventMutationVariables, useCreateMainEventMutation } from '../../graphql/mainEvents/generated/createMainEventMutation.generated';

const CreateMainEvent: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createMainEvent, { loading }] = useCreateMainEventMutation();

  const onSubmit = async (data: CreateMainEventMutationVariables) => {
    try {
      await createMainEvent({
        variables: data,
      });
      toast({
        title: 'Event erstellt',
        description: 'Das Event wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/main-events');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Event erstellen</Heading>
      <Button
        as={Link}
        to="/main-events"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <MainEventForm
        loading={loading}
        onSubmit={onSubmit}
        withPublish
      />
    </Layout>
  );
};

export default CreateMainEvent;
