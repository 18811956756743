import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PageFragmentDoc } from '../../fragments/generated/page.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePageMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
  event?: Types.InputMaybe<Types.Scalars['ID']>;
  content?: Types.InputMaybe<Types.Scalars['JSON']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
  order?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdatePageMutation = { __typename?: 'Mutation', updatePage: { __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string } };


export const UpdatePageDocument = gql`
    mutation UpdatePage($id: ID!, $name: String, $description: String, $locale: String, $event: ID, $content: JSON, $status: StatusTypes, $order: Int) {
  updatePage(
    id: $id
    input: {name: $name, description: $description, locale: $locale, event: $event, status: $status, content: $content, order: $order}
  ) {
    ...page
  }
}
    ${PageFragmentDoc}`;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      event: // value for 'event'
 *      content: // value for 'content'
 *      status: // value for 'status'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
      }
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;