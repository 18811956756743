import {
  Box, Button, Flex, Icon, IconButton, Spinner, Text, useOutsideClick,
} from '@chakra-ui/react';
import React, {
  FC, useRef, useState,
} from 'react';
import { GoSearch } from 'react-icons/go';
import { useDebouncedValue } from 'rooks';
import { pick } from 'lodash';
import { IoMdTrash } from 'react-icons/io';
import ICoordinates from '../models/ICoordinates';
import TextInput from './TextInput';
import { useForwardGeocodingQuery } from '../graphql/util/generated/forwardGeocodingQuery.generated';
import { GeoPlace } from '../types/graphql.generated';

interface CoordinatesInputProps {
  onChange?: (coords: any) => void,
  value?: ICoordinates | null,
  isInvalid?: boolean,
  withAdress?: boolean,
}

const CoordinatesInput: FC<CoordinatesInputProps> = ({
  children, isInvalid, onChange, value, withAdress,
}) => {
  const [address, setAddress] = useState<string>(withAdress && value && value.name ? value.name : '');
  const outsideRef = useRef<HTMLDivElement>(null);
  const [debouncedAddress] = useDebouncedValue(address, 300);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  useOutsideClick({
    ref: outsideRef,
    handler: () => setIsFocused(false),
  });
  const { data: addressData, loading } = useForwardGeocodingQuery({
    variables: {
      text: debouncedAddress || '',
    },
  });

  const selectPlace = (place: GeoPlace) => {
    if (onChange) {
      if (withAdress) {
        onChange({
          ...pick(place.coordinates, ['lat', 'lng']),
          name: place.name,
        });
      } else {
        onChange({
          ...pick(place.coordinates, ['lat', 'lng']),
        });
      }
      setAddress(place.name);
      setIsFocused(false);
    }
  };

  const reset = () => {
    if (onChange) {
      onChange(null);
      setAddress('');
    }
  };

  return (
    <Box
      ref={outsideRef}
      borderColor={isInvalid ? 'red.500' : 'transparent'}
      borderWidth="2px"
      borderRadius="lg"
      p={isInvalid ? '3' : '0'}
    >
      <Text
        w="full"
        fontWeight="bold"
        mb="8px"
      >
        {children}
      </Text>
      <Flex
        gap="4"
        alignItems="flex-end"
      >
        <TextInput
          value={address}
          onFocus={() => setIsFocused(true)}
          onChange={(e) => setAddress(e.target.value)}
          icon={<Icon as={GoSearch} />}
        >
          Adresse
        </TextInput>
        <IconButton
          colorScheme="red"
          size="sm"
          mb="1"
          onClick={reset}
          aria-label="Löschen"
          icon={<Icon as={IoMdTrash} />}
        />
      </Flex>
      {(isFocused && addressData) && (
        <Box
          boxShadow="md"
          borderRadius="md"
          p="4"
          overflow="hidden"
        >
          {(!loading && addressData.forwardGeocode.length > 0)
            && addressData.forwardGeocode.map((geoPlace) => (
              <Button
                key={geoPlace.name}
                variant="unstyled"
                isFullWidth
                textAlign="left"
                overflow="hidden"
                onClick={() => selectPlace(geoPlace)}
              >
                {geoPlace.name}
              </Button>
            ))}
          {(!loading && addressData.forwardGeocode.length === 0) && (
            <Text
              fontSize="md"
              fontWeight="bold"
            >
              Nichts gefunden
            </Text>
          )}
          {loading && (
            <Flex
              w="100%"
              justify="center"
            >
              <Spinner
                thickness="2px"
                color="teal"
                emptyColor="gray.200"
                size="md"
              />
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CoordinatesInput;
