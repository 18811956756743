import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { NewsWithEventFragmentDoc } from '../../fragments/generated/news.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateNewsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  title?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
  mainEvent?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type UpdateNewsMutation = { __typename?: 'Mutation', updateNews: { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string } | null } };


export const UpdateNewsDocument = gql`
    mutation UpdateNews($id: ID!, $title: String, $description: String, $locale: String, $mainEvent: ID) {
  updateNews(
    id: $id
    input: {title: $title, description: $description, locale: $locale, mainEvent: $mainEvent}
  ) {
    ...newsWithEvent
  }
}
    ${NewsWithEventFragmentDoc}`;
export type UpdateNewsMutationFn = Apollo.MutationFunction<UpdateNewsMutation, UpdateNewsMutationVariables>;

/**
 * __useUpdateNewsMutation__
 *
 * To run a mutation, you first call `useUpdateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewsMutation, { data, loading, error }] = useUpdateNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      mainEvent: // value for 'mainEvent'
 *   },
 * });
 */
export function useUpdateNewsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNewsMutation, UpdateNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNewsMutation, UpdateNewsMutationVariables>(UpdateNewsDocument, options);
      }
export type UpdateNewsMutationHookResult = ReturnType<typeof useUpdateNewsMutation>;
export type UpdateNewsMutationResult = Apollo.MutationResult<UpdateNewsMutation>;
export type UpdateNewsMutationOptions = Apollo.BaseMutationOptions<UpdateNewsMutation, UpdateNewsMutationVariables>;