import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { FC, useEffect, useState } from 'react';
import {
  DragDropContext, Draggable, Droppable, DropResult,
} from 'react-beautiful-dnd';
import { GoPlus } from 'react-icons/go';
import { v4 as uuidv4 } from 'uuid';
import FieldDecorator from './decorator';
import FieldRenderer from './renderer';
import { BlockInputItems } from '../../models';

interface BlockInputProps {
  type?: 'page' | 'form',
  onChange?: (json: string) => void,
  value?: string,
  locale?: string,
}

const pageOptions = {
  headline: 'Überschrift',
  paragraph: 'Absatz',
  asset: 'Medien',
  map: 'Karte',
  faq: 'FAQ',
  parking: 'Parkmöglichkeiten',
};

const formOptions = {
  'single-line': 'Einzeiliges Textfeld',
  'multi-line': 'Mehrzeiliges Textfeld',
  number: 'Zahlenfeld',
  email: 'E-Mail',
  phone: 'Telefon',
};

const reorder = (list: BlockInputItems[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const BlockInput: FC<BlockInputProps> = ({
  children,
  onChange,
  value,
  locale,
  type = 'page',
}) => {
  const defaultOption = type === 'page' ? 'headline' : 'single-line';
  const options: Record<string, string> = type === 'page' ? pageOptions : formOptions;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedBlock, setSelectedBlock] = useState<BlockInputItems['type']>(defaultOption);
  const [content, setContent] = useState<BlockInputItems[]>(JSON.parse(value || '[]'));

  const addBlock = () => {
    if (selectedBlock) {
      setContent([
        ...content,
        {
          type: selectedBlock,
          id: uuidv4(),
        },
      ]);
      onClose();
      setSelectedBlock(defaultOption);
    }
  };

  const deleteBlock = (id: string) => {
    setContent((prevState) => prevState.filter((item) => item.id !== id));
  };

  const onBlockChange = (field: BlockInputItems) => {
    const block = content.find((item) => item.id === field.id);
    if (JSON.stringify(block) === JSON.stringify(field)) return;

    setContent((prevState) => prevState.map((item) => {
      if (item.id === field.id) return field;

      return item;
    }));
  };

  useEffect(() => {
    if (onChange) {
      onChange(JSON.stringify(content));
    }
  }, [content]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      content,
      result.source.index,
      result.destination.index,
    );

    setContent(items);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box>
        <Text
          w="full"
          fontWeight="bold"
          mb="8px"
        >
          {children}
        </Text>
        <Droppable droppableId="droppable">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {content.map((field, index) => (
                <Draggable
                  key={field.id}
                  draggableId={field.id}
                  index={index}
                >
                  {(innerProvided) => (
                    <div
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <FieldDecorator
                        title={options[field.type]}
                        field={field}
                        onDelete={(id) => deleteBlock(id)}
                      >
                        <FieldRenderer
                          field={field}
                          locale={locale}
                          onChange={onBlockChange}
                        />
                      </FieldDecorator>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>

        <Popover
          isOpen={isOpen}
          onClose={onClose}
        >
          <PopoverTrigger>
            <Button
              colorScheme="teal"
              onClick={onOpen}
              leftIcon={<GoPlus />}
            >
              Erstellen
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Block auswählen</PopoverHeader>
            <PopoverContent>
              <RadioGroup
                defaultValue={defaultOption}
                value={selectedBlock}
                onChange={(newValue) => setSelectedBlock(newValue as BlockInputItems['type'])}
              >
                <Stack direction="column" spacing={4} p={4}>
                  {Object.entries(options).map(([optionValue, label]) => (
                    <Radio
                      key={optionValue}
                      value={optionValue}
                    >
                      {label}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
              <Box
                px={4}
                pb={4}
              >
                <Button
                  size="sm"
                  colorScheme="teal"
                  disabled={!selectedBlock}
                  onClick={addBlock}
                >
                  Auswählen
                </Button>
              </Box>
            </PopoverContent>
          </PopoverContent>
        </Popover>
      </Box>
    </DragDropContext>
  );
};

export default BlockInput;
