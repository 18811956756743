import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import EventForm from '../../components/event/EventForm';
import Layout from '../../components/Layout';
import { CreateEventMutationVariables, useCreateEventMutation } from '../../graphql/events/generated/createEventMutation.generated';

const CreateEvent: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createEvent, { loading }] = useCreateEventMutation();

  const onSubmit = async (data: CreateEventMutationVariables) => {
    try {
      await createEvent({
        variables: data,
      });
      toast({
        title: 'Veranstaltung erstellt',
        description: 'Veranstaltung wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/events');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Veranstaltung erstellen</Heading>
      <Button
        as={Link}
        to="/events"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <EventForm
        loading={loading}
        onSubmit={onSubmit}
        persist
        withPublish
      />
    </Layout>
  );
};

export default CreateEvent;
