import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { NewsWithEventFragmentDoc } from '../../fragments/generated/news.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NewsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type NewsQuery = { __typename?: 'Query', news: { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string } | null } };


export const NewsDocument = gql`
    query News($id: ID!) {
  news(id: $id) {
    ...newsWithEvent
  }
}
    ${NewsWithEventFragmentDoc}`;

/**
 * __useNewsQuery__
 *
 * To run a query within a React component, call `useNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsQuery(baseOptions: Apollo.QueryHookOptions<NewsQuery, NewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
      }
export function useNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsQuery, NewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NewsQuery, NewsQueryVariables>(NewsDocument, options);
        }
export type NewsQueryHookResult = ReturnType<typeof useNewsQuery>;
export type NewsLazyQueryHookResult = ReturnType<typeof useNewsLazyQuery>;
export type NewsQueryResult = Apollo.QueryResult<NewsQuery, NewsQueryVariables>;