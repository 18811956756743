import {
  Box,
  Button, Flex, Text,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { parseISO } from 'date-fns';
import TextInput from '../TextInput';
import { CreateNotificationMutationVariables } from '../../graphql/notifications/generated/createNotification.generated';
import { EventQuery } from '../../graphql/events/generated/eventQuery.generated';
import TextareaInput from '../TextareaInput';
import RelationshipInput from '../RelationshipInput';
import { MainEventsDocument } from '../../graphql/mainEvents/generated/mainEvents.generated';

interface FormValues {
  de?: {
    title: string,
    body: string,
    mainEvent?: EventQuery['event']['mainEvent'],
  },
  en?: {
    title: string,
    body: string,
    mainEvent?: EventQuery['event']['mainEvent'],
  },
  scheduleForDate?: string,
}

interface NotificationFormProps {
  onSubmit: (data: CreateNotificationMutationVariables) => void,
  loading?: boolean,
  defaultValues?: FormValues,
}

const NotificationForm: FC<NotificationFormProps> = ({ onSubmit, loading, defaultValues }) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control,
  } = useForm<FormValues>({ defaultValues });

  const onSave = async (data: FormValues) => {
    const params = {
      ...data,
      ...(data.en && {
        en: {
          ...data.en,
          mainEvent: data.en.mainEvent ? data.en.mainEvent.id : null,
        },
      }),
      ...(data.de && {
        de: {
          ...data.de,
          mainEvent: data.de.mainEvent ? data.de.mainEvent.id : null,
        },
      }),
    } as CreateNotificationMutationVariables;
    await onSubmit(params);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Controller
          render={
            ({ field }) => (
              <Box>
                <Text
                  w="full"
                  fontWeight="bold"
                  mb="-15px"
                >
                  Senden am:
                </Text>
                <DatePicker
                  showTimeSelect
                  locale="de-DE"
                  timeCaption="Zeit"
                  timeIntervals={15}
                  dateFormat="EEEE d. LLLL yyyy HH:mm"
                  customInput={<TextInput />}
                  selected={field.value ? parseISO(field.value) : null}
                  onChange={(newDate) => field.onChange(newDate?.toISOString())}
                />
              </Box>
            )
          }
          control={control}
          name="scheduleForDate"
        />
        <TextInput
          isInvalid={!!errors.de?.title}
          {...register('de.title')}
          placeholder="Titel Deutsch..."
        >
          Titel Deutsch:
        </TextInput>
        <TextareaInput
          isInvalid={!!errors.de?.title}
          {...register('de.body')}
          placeholder="Nachricht Deutsch..."
        >
          Nachricht Deutsch:
        </TextareaInput>
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={MainEventsDocument}
                isInvalid={!!errors.de?.mainEvent}
                value={field.value as any}
                onChange={field.onChange}
                locale="de"
              >
                Event Deutsch:
              </RelationshipInput>
            )
          }
          control={control}
          name="de.mainEvent"
        />
        <TextInput
          isInvalid={!!errors.en?.title}
          {...register('en.title')}
          placeholder="Titel Englisch..."
        >
          Titel Englisch:
        </TextInput>
        <TextareaInput
          isInvalid={!!errors.en?.title}
          {...register('en.body')}
          placeholder="Nachricht Englisch..."
        >
          Nachricht Englisch:
        </TextareaInput>
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={MainEventsDocument}
                isInvalid={!!errors.en?.mainEvent}
                value={field.value as any}
                onChange={field.onChange}
                locale="en"
              >
                Event Englisch:
              </RelationshipInput>
            )
          }
          control={control}
          name="en.mainEvent"
        />
      </Flex>
      <Button
        mt="30px"
        type="submit"
        colorScheme="teal"
        isLoading={loading}
      >
        Senden
      </Button>
    </form>
  );
};

export default NotificationForm;
