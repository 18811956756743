import {
  Button, FormControl, FormLabel, Heading, Switch, useToast,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FormsForm from '../../components/forms/FormsForm';
import Layout from '../../components/Layout';
import { CreateFormMutationVariables } from '../../graphql/forms/generated/createFormMutation.generated';
import { useFormQuery } from '../../graphql/forms/generated/formQuery.generated';
import { useUpdateFormMutation } from '../../graphql/forms/generated/updateFormMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdateForm: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const [updateForm, { loading }] = useUpdateFormMutation();

  const { formId } = useParams() as { formId: string };

  const { data: formResponse } = useFormQuery({
    variables: {
      id: formId,
    },
  });

  const onSubmit = async (data: CreateFormMutationVariables) => {
    try {
      await updateForm({
        variables: {
          id: formId,
          ...data,
        },
      });
      toast({
        title: 'Formular geändert',
        description: 'Das Formular wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/forms');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updateForm({
        variables: {
          id: formId,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Formular geändert',
        description: 'Das Formular wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Formular ändern</Heading>
      <Button
        as={Link}
        to="/forms"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {formResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={formResponse.form.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <FormsForm
            locale={formResponse.form.locale}
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={formResponse.form}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdateForm;
