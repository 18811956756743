import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { FormFragmentDoc } from '../../fragments/generated/form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type FormQuery = { __typename?: 'Query', form: { __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string } };


export const FormDocument = gql`
    query Form($id: ID!) {
  form(id: $id) {
    ...form
  }
}
    ${FormFragmentDoc}`;

/**
 * __useFormQuery__
 *
 * To run a query within a React component, call `useFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormQuery(baseOptions: Apollo.QueryHookOptions<FormQuery, FormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormQuery, FormQueryVariables>(FormDocument, options);
      }
export function useFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormQuery, FormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormQuery, FormQueryVariables>(FormDocument, options);
        }
export type FormQueryHookResult = ReturnType<typeof useFormQuery>;
export type FormLazyQueryHookResult = ReturnType<typeof useFormLazyQuery>;
export type FormQueryResult = Apollo.QueryResult<FormQuery, FormQueryVariables>;