import { Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { NotificationsQuery } from '../../../graphql/notifications/generated/notifications.generated';

const NotificationColumn: FC<{ item: NotificationsQuery['notifications']['docs'][number] }> = ({ item }) => (
  <>
    <Td>
      {item.en?.title}
    </Td>
    <Td>
      {item.de?.title}
    </Td>
    <Td>
      {item.numberOfMessages || 0}
    </Td>
    <Td>
      {item.successfulMessages || 0}
    </Td>
    <Td>
      {item.failedMessages || 0}
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default NotificationColumn;
