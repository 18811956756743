import { Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { ScheduledNotificationsQuery } from '../../../graphql/notifications/generated/scheduledNotifications.generated';

const ScheduledNotificationColumn: FC<{ item: ScheduledNotificationsQuery['scheduledNotifications']['docs'][number] }> = ({ item }) => (
  <>
    <Td>
      {item.en?.title}
    </Td>
    <Td>
      {item.de?.title}
    </Td>
    <Td>
      {format(new Date(item.date ?? ''), 'd.M.yyyy HH:mm')}
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default ScheduledNotificationColumn;
