import React, { FC } from 'react';
import { Text } from '@chakra-ui/react';
import { IParkingBlock } from '../../../models/blocks';

interface ParkingProps {
  field: IParkingBlock,
}

const Parking: FC<ParkingProps> = () => (
  <div>
    <Text mt="2">
      An dieser Stelle werden alle Parkmöglichkeiten der
      {' '}
      Stadt Salzburg aufgelistet (gleicher Inhalt wie Website).
    </Text>
  </div>

);

export default Parking;
