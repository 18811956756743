import React, { FC, useState } from 'react';
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import { useCreateMediaMutation } from '../../graphql/media/generated/createMediaMutation.generated';
import { Media } from '../../types/graphql.generated';
import MediaForm, { MediaFormSubmit } from './MediaForm';
import MediaSelectorList from './MediaSelectorList';

interface MediaSelectorModalProps {
  isOpen?: boolean,
  locale?: string,
  onClose: () => void,
  onSelect: (media: Omit<Media, 'width' | 'height'>) => void;
}

const MediaSelectorModal: FC<MediaSelectorModalProps> = ({
  isOpen, onClose, onSelect, locale = 'de',
}) => {
  const [selected, setSelected] = useState<Omit<Media, 'width' | 'height'> | null>();
  const toast = useToast();
  const [createMedia, { loading }] = useCreateMediaMutation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const onMediaSave = async (data: MediaFormSubmit) => {
    if (data.file.length === 0) return;
    try {
      const createdMedia = await createMedia({
        variables: {
          ...data,
          file: data.file[0],
        },
        update: (cache) => {
          cache.evict({ fieldName: 'medias' });
        },
      });
      toast({
        title: 'Bild/Video hochgeladen',
        description: 'Die ausgewählte Ressource wurde hochgeladen',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      setTabIndex(0);
      if (createdMedia.data) {
        setSelected(createdMedia.data.createMedia);
      }
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const handleSelect = () => {
    if (selected) {
      onSelect(selected);
      onClose();
    }
  };

  return (
    <Modal isOpen={!!isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Bild/Video auswählen</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            <TabList>
              <Tab>Medien auswählen</Tab>
              <Tab>Medien hochladen</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <MediaSelectorList
                  locale={locale}
                  onSelect={(media) => setSelected(media)}
                  selected={selected}
                />
              </TabPanel>
              <TabPanel>
                <Heading>Bilder oder video hochladen</Heading>
                <MediaForm
                  onSubmit={onMediaSave}
                  loading={loading}
                  locale={locale}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            onClick={onClose}
          >
            Schließen
          </Button>
          <Button
            colorScheme="teal"
            disabled={!selected}
            onClick={handleSelect}
          >
            Auswählen
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MediaSelectorModal;
