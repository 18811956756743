import {
  Button,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { MdCopyAll, MdEdit } from 'react-icons/md';
import { BsFillBellFill } from 'react-icons/bs';
import { TableData } from '../../models/table';
import Column from './columns';
import DeleteButton from './DeleteButton';

interface InnerTableProps {
  items?: TableData[],
  loading?: boolean,
  pathPrefix?: string,
  onDelete?: (id: string) => void,
  tableHead: ReactNode,
  onCopy?: (id: string, locale: string) => void,
  onNotification?: (id: string) => void,
}

const InnerTable: FC<InnerTableProps> = ({
  items,
  loading,
  onDelete,
  tableHead,
  onCopy,
  pathPrefix,
  onNotification,
}) => (
  <>
    <Table size="md" mt="8">
      <Thead>
        <Tr>
          {tableHead}
          <Th />
        </Tr>
      </Thead>
      {(items && !loading && items.length > 0) && (
        <Tbody>
          {items.map((item) => (
            <Tr key={item.id}>
              <Column
                item={item}
              />
              {(onDelete && pathPrefix) && (
                <Td>
                  <Flex gap="2">
                    <IconButton
                      as={Link}
                      to={`/${pathPrefix}/${item.id}/update`}
                      colorScheme="teal"
                      size="sm"
                      aria-label="Zeile ändern"
                      icon={<Icon as={MdEdit} />}
                    />
                    <DeleteButton
                      item={item}
                      onDelete={onDelete}
                    />
                    {onCopy && (
                      <Popover>
                        <PopoverTrigger>
                          <IconButton
                            colorScheme="teal"
                            size="sm"
                            aria-label="Zeile duplizieren"
                            icon={<Icon as={MdCopyAll} />}
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverCloseButton />
                          <PopoverHeader>Inhalt duplizieren</PopoverHeader>
                          <PopoverBody>
                            <Flex gap="2">
                              <Button
                                onClick={() => onCopy(item.id, 'de')}
                              >
                                DE
                              </Button>
                              <Button
                                onClick={() => onCopy(item.id, 'en')}
                              >
                                EN
                              </Button>
                            </Flex>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                    {onNotification && (
                      <IconButton
                        onClick={() => onNotification(item.id)}
                        colorScheme="teal"
                        size="sm"
                        aria-label="Benachrichtigung erneut senden"
                        icon={<Icon as={BsFillBellFill} />}
                      />
                    )}
                  </Flex>
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      )}
    </Table>
    {(loading) && (
      <Flex
        w="100%"
        justify="center"
        mt="10"
      >
        <Spinner
          thickness="4px"
          color="teal"
          emptyColor="gray.200"
          size="xl"
        />
      </Flex>
    )}
    {(items && !loading && items.length === 0) && (
      <Flex
        w="100%"
        justify="center"
        mt="6"
      >
        <Text
          fontSize="2xl"
          fontWeight="bold"
        >
          Nichts gefunden.
        </Text>
      </Flex>
    )}
  </>
);

export default InnerTable;
