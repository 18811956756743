import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PageFragmentDoc } from './page.generated';
export type MainEventFragment = { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null };

export type MainEventWithPagesFragment = { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, pages: Array<{ __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string }>, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null };

export const MainEventFragmentDoc = gql`
    fragment mainEvent on MainEvent {
  id
  name
  status
  locale
  description
  content
  showScheduleLink
  position
  disableInfoAndProgram
  mainPageId
  logo {
    id
    name
    locale
    altText
    caption
    originalUrl
    type
    mimetype
    formats {
      xs {
        url
        width
      }
      s {
        url
        width
      }
      m {
        url
        width
      }
      l {
        url
        width
      }
      xl {
        url
        width
      }
    }
    createdAt
    updatedAt
  }
  createdAt
  updatedAt
}
    `;
export const MainEventWithPagesFragmentDoc = gql`
    fragment mainEventWithPages on MainEvent {
  ...mainEvent
  pages {
    ...page
  }
}
    ${MainEventFragmentDoc}
${PageFragmentDoc}`;