import React, { FC } from 'react';
import { ISingleLineBlock } from '../../../models/forms';
import TextInput from '../../TextInput';

interface SingleLineProps {
  field: ISingleLineBlock,
  onChange: (value: ISingleLineBlock) => void,
}

const SingleLine: FC<SingleLineProps> = ({ field, onChange }) => (
  <>
    <TextInput
      value={field.label || ''}
      onChange={(e) => onChange({ ...field, label: e.target.value })}
    >
      Titel:
    </TextInput>
    <TextInput
      value={field.placeholder || ''}
      onChange={(e) => onChange({ ...field, placeholder: e.target.value })}
    >
      Platzhalter:
    </TextInput>
  </>
);

export default SingleLine;
