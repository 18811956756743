import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import Home from './pages/Home';
import Artists from './pages/artists/Artists';
import CreateArtist from './pages/artists/CreateArtist';
import Medias from './pages/media/Medias';
import CreateMedia from './pages/media/CreateMedia';
import UpdateMedia from './pages/media/UpdateMedia';
import UpdateArtist from './pages/artists/UpdateArtist';
import Places from './pages/places/Places';
import CreatePlace from './pages/places/CreatePlace';
import UpdatePlace from './pages/places/UpdatePlace';
import MainEvents from './pages/main-events/MainEvents';
import CreateMainEvent from './pages/main-events/CreateMainEvent';
import UpdateMainEvent from './pages/main-events/UpdateMainEvent';
import UpdatePage from './pages/pages/UpdatePage';
import CreatePage from './pages/pages/CreatePage';
import News from './pages/news/News';
import CreateNews from './pages/news/CreateNews';
import UpdateNews from './pages/news/UpdateNews';
import Events from './pages/events/Events';
import CreateEvent from './pages/events/CreateEvent';
import UpdateEvent from './pages/events/UpdateEvent';
import Notifications from './pages/notifications/Notifications';
import CreateNotification from './pages/notifications/CreateNotification';
import CreatePopup from './pages/notifications/CreatePopup';
import UpdateNotification from './pages/notifications/UpdateNotification';
import Forms from './pages/forms/Forms';
import CreateForm from './pages/forms/CreateForm';
import UpdateForm from './pages/forms/UpdateForm';

const App: FC = () => (
  <ChakraProvider>
    <CSSReset />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="artists">
        <Route index element={<Artists />} />
        <Route path="create" element={<CreateArtist />} />
        <Route path=":id/update" element={<UpdateArtist />} />
      </Route>
      <Route path="media">
        <Route index element={<Medias />} />
        <Route path="create" element={<CreateMedia />} />
        <Route path=":id/update" element={<UpdateMedia />} />
      </Route>
      <Route path="places">
        <Route index element={<Places />} />
        <Route path="create" element={<CreatePlace />} />
        <Route path=":id/update" element={<UpdatePlace />} />
      </Route>
      <Route path="news">
        <Route index element={<News />} />
        <Route path="create" element={<CreateNews />} />
        <Route path=":id/update" element={<UpdateNews />} />
      </Route>
      <Route path="events">
        <Route index element={<Events />} />
        <Route path="create" element={<CreateEvent />} />
        <Route path=":id/update" element={<UpdateEvent />} />
      </Route>
      <Route path="main-events">
        <Route index element={<MainEvents />} />
        <Route path="create" element={<CreateMainEvent />} />
        <Route path=":id/update" element={<UpdateMainEvent />} />
        <Route path=":eventId/:locale/pages/:pageId/update" element={<UpdatePage />} />
        <Route path=":eventId/:locale/pages/create" element={<CreatePage />} />
      </Route>
      <Route path="notifications">
        <Route index element={<Notifications />} />
        <Route path="create" element={<CreateNotification />} />
        <Route path="call-to-subscribe" element={<CreatePopup />} />
      </Route>
      <Route path="forms">
        <Route index element={<Forms />} />
        <Route path="create" element={<CreateForm />} />
        <Route path=":formId/update" element={<UpdateForm />} />
      </Route>
      <Route path="scheduled-notifications/:id/update" element={<UpdateNotification />} />
    </Routes>
  </ChakraProvider>
);

export default App;
