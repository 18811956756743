import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Th,
  useToast,
} from '@chakra-ui/react';
import { MdEmail, MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useDebouncedValue } from 'rooks';
import { GoSearch } from 'react-icons/go';
import Layout from '../../components/Layout';
import { PlacesQueryVariables } from '../../graphql/places/generated/placesQuery.generated';
import InnerTable from '../../components/table/InnerTable';
import {
  NotificationsQueryVariables,
  useNotificationsQuery,
} from '../../graphql/notifications/generated/notifications.generated';
import {
  ScheduledNotificationsQueryVariables,
  useScheduledNotificationsQuery,
} from '../../graphql/notifications/generated/scheduledNotifications.generated';
import {
  useDeleteScheduledNotificationMutation,
} from '../../graphql/notifications/generated/deleteScheduledNotificationMutation.generated';
import TextInput from '../../components/TextInput';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

/* eslint-disable max-len */
const Notifications: FC = () => {
  const toast = useToast();
  const [notificationVariables, setNotificationVariables] = useState<NotificationsQueryVariables>({
    limit: LIMIT,
    offset: 0,
    term: '',
  });
  const [deleteScheduledNotification] = useDeleteScheduledNotificationMutation();

  const [notificationTerm, setNotificationTerm] = useState<string>('');

  const [debouncedNotificationTerm] = useDebouncedValue(notificationTerm, 300);

  const [scheduledTerm, setScheduledTerm] = useState<string>('');

  const [scheduled2Term, setScheduled2Term] = useState<string>('');

  const [debouncedScheduledTerm] = useDebouncedValue(scheduledTerm, 300);

  const [debouncedScheduled2Term] = useDebouncedValue(scheduled2Term, 300);

  const [pageScheduledScope, setPageScheduledScope] = useState<{
    limit: number,
    offset: number,
  }>({
    limit: 3,
    offset: 0,
  });

  const [pageScheduled2Scope, setPageScheduled2Scope] = useState<{
    limit: number,
    offset: number,
  }>({
    limit: 3,
    offset: 0,
  });

  const [pageNotificationsScope, setPageNotificationsScope] = useState<{
    limit: number,
    offset: number,
  }>({
    limit: 3,
    offset: 0,
  });

  const [
    scheduledNotificationVariables,
    setScheduledNotificationVariables,
  ] = useState<ScheduledNotificationsQueryVariables>({
    limit: LIMIT,
    offset: 0,
    term: '',
    hasIdentifier: false,
  });

  const [
    scheduledNotification2Variables,
    setScheduledNotification2Variables,
  ] = useState<ScheduledNotificationsQueryVariables>({
    limit: LIMIT,
    offset: 0,
    term: '',
    hasIdentifier: true,
  });

  const onNotificationVariablesChange = (params: Partial<PlacesQueryVariables>) => {
    setNotificationVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const onScheduledNotificationVariablesChange = (params: Partial<PlacesQueryVariables>) => {
    setScheduledNotificationVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const onScheduledNotification2VariablesChange = (params: Partial<PlacesQueryVariables>) => {
    setScheduledNotification2Variables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { data: notifications, loading: notificationsLoading } = useNotificationsQuery({
    variables: notificationVariables,
    fetchPolicy: 'cache-and-network',
    pollInterval: 20000,
  });

  const {
    data: scheduledNotifications,
    loading: scheduledNotificationsLoading,
    refetch: scheduledNotificationsRefetch,
  } = useScheduledNotificationsQuery({
    variables: scheduledNotificationVariables,
    fetchPolicy: 'cache-and-network',
    pollInterval: 20000,
  });

  const {
    data: scheduledNotifications2,
    loading: scheduledNotifications2Loading,
  } = useScheduledNotificationsQuery({
    variables: scheduledNotification2Variables,
    fetchPolicy: 'cache-and-network',
    pollInterval: 20000,
  });

  const goToPageNotifications = (newPage: number) => {
    onNotificationVariablesChange({
      offset: newPage * LIMIT,
    });
  };

  const onOffsetNotificationsChange = (newOffset: number) => {
    setPageNotificationsScope({
      ...pageNotificationsScope,
      offset: newOffset,
    });
  };

  const onOffsetScheduledNotifications2Change = (newOffset: number) => {
    setPageScheduled2Scope({
      ...pageScheduled2Scope,
      offset: newOffset,
    });
  };

  const goToPageScheduledNotifications = (newPage: number) => {
    onScheduledNotificationVariablesChange({
      offset: newPage * LIMIT,
    });
  };

  const goToPageScheduledNotifications2 = (newPage: number) => {
    onScheduledNotification2VariablesChange({
      offset: newPage * LIMIT,
    });
  };

  const onOffsetScheduledNotificationsChange = (newOffset: number) => {
    setPageScheduledScope({
      ...pageScheduledScope,
      offset: newOffset,
    });
  };

  useEffect(() => {
    if (debouncedNotificationTerm !== null) {
      onNotificationVariablesChange({ term: debouncedNotificationTerm });
    }
  }, [debouncedNotificationTerm]);

  useEffect(() => {
    if (debouncedScheduledTerm !== null) {
      onScheduledNotificationVariablesChange({ term: debouncedScheduledTerm });
    }
  }, [debouncedScheduledTerm]);

  useEffect(() => {
    if (debouncedScheduled2Term !== null) {
      onScheduledNotification2VariablesChange({ term: debouncedScheduled2Term });
    }
  }, [debouncedScheduled2Term]);

  const onScheduledDelete = async (id: string) => {
    try {
      await deleteScheduledNotification({
        variables: {
          id,
        },
      });
      toast({
        title: 'Benachrichtigung wurde gelöscht',
        description: 'Die ausgewählte Benachrichtigung wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      scheduledNotificationsRefetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Benachrichtigungen</Heading>
      <Flex justify="end" align="center" gap="4" my="4">
        <Button
          as={Link}
          to="/notifications/create"
          colorScheme="teal"
          leftIcon={<MdEmail />}
        >
          Benachrichtigung senden
        </Button>
        <Button
          as={Link}
          to="/notifications/call-to-subscribe"
          leftIcon={<AiFillQuestionCircle />}
        >
          Events hinzugefügt (Popup)
        </Button>
      </Flex>
      <Tabs>
        <TabList>
          <Tab>Gesendete Benachrichtigungen</Tab>
          <Tab>Geplante Benachrichtigungen</Tab>
          <Tab>Geplante Benachrichtigungen (15min vor Event)</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box
              w="230px"
            >
              <TextInput
                placeholder="Suche..."
                type="text"
                onChange={(e) => setNotificationTerm(e.target.value)}
                value={notificationTerm}
                icon={<Icon as={GoSearch} />}
              >
                Suche:
              </TextInput>
            </Box>
            <InnerTable
              items={notifications ? notifications.notifications.docs : []}
              loading={notificationsLoading}
              tableHead={(
                <>
                  <Th>Titel EN</Th>
                  <Th>Titel DE</Th>
                  <Th>Gesendete Nachrichten</Th>
                  <Th>Erfolgreich gesendet</Th>
                  <Th>Senden Fehlgeschlagen</Th>
                  <Th>Erstellt</Th>
                </>
              )}
            />
            {(!notificationsLoading && notifications) && (
              <Flex
                mt="20px"
                justify="end"
              >
                <ButtonGroup isAttached size="sm" variant="outline">
                  {pageNotificationsScope.offset > 0 && (
                    <IconButton
                      onClick={() => onOffsetNotificationsChange(pageNotificationsScope.offset - 1)}
                      aria-label="Vorherige Seite"
                      icon={<MdNavigateBefore />}
                    />
                  )}
                  {Array(notifications.notifications.totalPages).fill('').map((_, index) => {
                    if (
                      index < pageNotificationsScope.offset
                      || index >= pageNotificationsScope.offset + pageNotificationsScope.limit
                    ) return null;

                    return (
                      <Button
                        isActive={notifications.notifications.page === index + 1}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => goToPageNotifications(index)}
                      >
                        {index + 1}
                      </Button>
                    );
                  })}
                  {notifications.notifications.totalPages && pageNotificationsScope.offset < notifications.notifications.totalPages - pageNotificationsScope.limit && (
                    <IconButton
                      onClick={() => onOffsetNotificationsChange(pageNotificationsScope.offset + 1)}
                      aria-label="Nächste Seite"
                      icon={<MdNavigateNext />}
                    />
                  )}
                </ButtonGroup>
              </Flex>
            )}
          </TabPanel>
          <TabPanel>
            <Box
              w="230px"
            >
              <TextInput
                placeholder="Suche..."
                type="text"
                onChange={(e) => setScheduledTerm(e.target.value)}
                value={scheduledTerm}
                icon={<Icon as={GoSearch} />}
              >
                Suche:
              </TextInput>
            </Box>
            <InnerTable
              onDelete={onScheduledDelete}
              pathPrefix="scheduled-notifications"
              items={
                scheduledNotifications ? scheduledNotifications.scheduledNotifications.docs : []
              }
              loading={scheduledNotificationsLoading}
              tableHead={(
                <>
                  <Th>Titel EN</Th>
                  <Th>Titel DE</Th>
                  <Th>Geplant für</Th>
                  <Th>Erstellt</Th>
                </>
              )}
            />
            {(!scheduledNotificationsLoading && scheduledNotifications) && (
              <Flex
                mt="20px"
                justify="end"
              >
                <ButtonGroup isAttached size="sm" variant="outline">
                  {pageScheduledScope.offset > 0 && (
                    <IconButton
                      onClick={() => onOffsetScheduledNotificationsChange(pageScheduledScope.offset - 1)}
                      aria-label="Vorherige Seite"
                      icon={<MdNavigateBefore />}
                    />
                  )}
                  {Array(scheduledNotifications.scheduledNotifications.totalPages).fill('').map((_, index) => {
                    if (
                      index < pageScheduledScope.offset
                      || index >= pageScheduledScope.offset + pageScheduledScope.limit
                    ) return null;

                    return (
                      <Button
                        isActive={scheduledNotifications.scheduledNotifications.page === index + 1}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => goToPageScheduledNotifications(index)}
                      >
                        {index + 1}
                      </Button>
                    );
                  })}
                  {(
                    scheduledNotifications.scheduledNotifications.totalPages
                    && pageScheduledScope.offset < scheduledNotifications.scheduledNotifications.totalPages - pageScheduledScope.limit
                  ) && (
                  <IconButton
                    onClick={() => onOffsetScheduledNotificationsChange(pageScheduledScope.offset + 1)}
                    aria-label="Nächste Seite"
                    icon={<MdNavigateNext />}
                  />
                  )}
                </ButtonGroup>
              </Flex>
            )}
          </TabPanel>

          <TabPanel>
            <Box
              w="230px"
            >
              <TextInput
                placeholder="Suche..."
                type="text"
                onChange={(e) => setScheduled2Term(e.target.value)}
                value={scheduled2Term}
                icon={<Icon as={GoSearch} />}
              >
                Suche:
              </TextInput>
            </Box>
            <InnerTable
              pathPrefix="scheduled-notifications"
              items={
                scheduledNotifications2 ? scheduledNotifications2.scheduledNotifications.docs : []
              }
              loading={scheduledNotifications2Loading}
              tableHead={(
                <>
                  <Th>Titel EN</Th>
                  <Th>Titel DE</Th>
                  <Th>Geplant für</Th>
                  <Th>Erstellt</Th>
                </>
              )}
            />
            {(!scheduledNotifications2Loading && scheduledNotifications2) && (
              <Flex
                mt="20px"
                justify="end"
              >
                <ButtonGroup isAttached size="sm" variant="outline">
                  {pageScheduled2Scope.offset > 0 && (
                    <IconButton
                      onClick={() => onOffsetScheduledNotifications2Change(pageScheduled2Scope.offset - 1)}
                      aria-label="Vorherige Seite"
                      icon={<MdNavigateBefore />}
                    />
                  )}
                  {Array(scheduledNotifications2.scheduledNotifications.totalPages).fill('').map((_, index) => {
                    if (
                      index < pageScheduled2Scope.offset
                      || index >= pageScheduled2Scope.offset + pageScheduled2Scope.limit
                    ) return null;

                    return (
                      <Button
                        isActive={scheduledNotifications2.scheduledNotifications.page === index + 1}
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        onClick={() => goToPageScheduledNotifications2(index)}
                      >
                        {index + 1}
                      </Button>
                    );
                  })}
                  {(
                    scheduledNotifications2.scheduledNotifications.totalPages
                    && pageScheduled2Scope.offset < scheduledNotifications2.scheduledNotifications.totalPages - pageScheduled2Scope.limit
                  ) && (
                  <IconButton
                    onClick={() => onOffsetScheduledNotifications2Change(pageScheduled2Scope.offset + 1)}
                    aria-label="Nächste Seite"
                    icon={<MdNavigateNext />}
                  />
                  )}
                </ButtonGroup>
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  );
};

export default Notifications;
/* eslint-enable max-len */
