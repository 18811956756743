import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type FormFragment = { __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string };

export const FormFragmentDoc = gql`
    fragment form on Form {
  id
  name
  status
  locale
  description
  content
  createdAt
  updatedAt
}
    `;