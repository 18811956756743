import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { NewsWithEventFragmentDoc } from '../../fragments/generated/news.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNewsMutationVariables = Types.Exact<{
  title: Types.Scalars['String'];
  description: Types.Scalars['String'];
  locale: Types.Scalars['String'];
  mainEvent?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type CreateNewsMutation = { __typename?: 'Mutation', createNews: { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string } | null } };


export const CreateNewsDocument = gql`
    mutation CreateNews($title: String!, $description: String!, $locale: String!, $mainEvent: ID) {
  createNews(
    title: $title
    description: $description
    locale: $locale
    mainEvent: $mainEvent
  ) {
    ...newsWithEvent
  }
}
    ${NewsWithEventFragmentDoc}`;
export type CreateNewsMutationFn = Apollo.MutationFunction<CreateNewsMutation, CreateNewsMutationVariables>;

/**
 * __useCreateNewsMutation__
 *
 * To run a mutation, you first call `useCreateNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsMutation, { data, loading, error }] = useCreateNewsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      mainEvent: // value for 'mainEvent'
 *   },
 * });
 */
export function useCreateNewsMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsMutation, CreateNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewsMutation, CreateNewsMutationVariables>(CreateNewsDocument, options);
      }
export type CreateNewsMutationHookResult = ReturnType<typeof useCreateNewsMutation>;
export type CreateNewsMutationResult = Apollo.MutationResult<CreateNewsMutation>;
export type CreateNewsMutationOptions = Apollo.BaseMutationOptions<CreateNewsMutation, CreateNewsMutationVariables>;