import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import NewsForm from '../../components/news/NewsForm';
import { CreateNewsMutationVariables, useCreateNewsMutation } from '../../graphql/news/generated/createNewsMutation.generated';

const CreateNews: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createNews, { loading }] = useCreateNewsMutation();

  const onSubmit = async (data: CreateNewsMutationVariables) => {
    try {
      await createNews({
        variables: data,
      });
      toast({
        title: 'News erstellt',
        description: 'News wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/news');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>News erstellen</Heading>
      <Button
        as={Link}
        to="/news"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <NewsForm
        loading={loading}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default CreateNews;
