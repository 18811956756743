import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PlaceFragmentDoc } from '../../fragments/generated/place.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlacesQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  locale: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
  mainEvent?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type PlacesQuery = { __typename?: 'Query', places: { __typename?: 'PlacePaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'Place', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null, coordinates?: { __typename?: 'Coordinate', lat?: number | null, lng?: number | null, name?: string | null } | null }> } };


export const PlacesDocument = gql`
    query Places($term: String!, $status: StatusTypes, $locale: String!, $limit: Int!, $offset: Int!, $mainEvent: String) {
  places(
    term: $term
    status: $status
    locale: $locale
    limit: $limit
    offset: $offset
    mainEvent: $mainEvent
  ) {
    docs {
      ...place
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    ${PlaceFragmentDoc}`;

/**
 * __usePlacesQuery__
 *
 * To run a query within a React component, call `usePlacesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlacesQuery({
 *   variables: {
 *      term: // value for 'term'
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      mainEvent: // value for 'mainEvent'
 *   },
 * });
 */
export function usePlacesQuery(baseOptions: Apollo.QueryHookOptions<PlacesQuery, PlacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlacesQuery, PlacesQueryVariables>(PlacesDocument, options);
      }
export function usePlacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlacesQuery, PlacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlacesQuery, PlacesQueryVariables>(PlacesDocument, options);
        }
export type PlacesQueryHookResult = ReturnType<typeof usePlacesQuery>;
export type PlacesLazyQueryHookResult = ReturnType<typeof usePlacesLazyQuery>;
export type PlacesQueryResult = Apollo.QueryResult<PlacesQuery, PlacesQueryVariables>;