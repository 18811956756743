import {
  Button, Heading, useToast,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import NewsForm from '../../components/news/NewsForm';
import { CreateNewsMutationVariables } from '../../graphql/news/generated/createNewsMutation.generated';
import { useNewsQuery } from '../../graphql/news/generated/newsQuery.generated';
import { useUpdateNewsMutation } from '../../graphql/news/generated/updateNewsMutation.generated';

const UpdateNews: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updateNews, { loading }] = useUpdateNewsMutation();

  const { data: newsResponse } = useNewsQuery({
    variables: {
      id,
    },
  });

  const onSubmit = async (data: CreateNewsMutationVariables) => {
    try {
      await updateNews({
        variables: {
          id,
          ...data,
        },
      });
      toast({
        title: 'News geändert',
        description: 'News wurden geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/news');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>News ändern</Heading>
      <Button
        as={Link}
        to="/news"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {newsResponse && (
        <NewsForm
          loading={loading}
          onSubmit={onSubmit}
          defaultValues={newsResponse.news}
          noNotification
        />
      )}
    </Layout>
  );
};

export default UpdateNews;
