import React, { FC, useState } from 'react';
import {
  Box, Button, Flex, IconButton, useMediaQuery,
} from '@chakra-ui/react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { VscMenu } from 'react-icons/vsc';
import { FaGuitar } from 'react-icons/fa';
import { MdEventNote, MdFestival } from 'react-icons/md';
import {
  BsPinMapFill, BsFillImageFill, BsNewspaper, BsFillBellFill,
} from 'react-icons/bs';
import { FaWpforms } from 'react-icons/fa6';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const navItems = [
  {
    title: 'Events',
    path: '/main-events',
    icon: <MdFestival />,
  },
  {
    title: 'Veranstaltungen',
    path: '/events',
    icon: <MdEventNote />,
  },
  {
    title: 'Künstler',
    path: '/artists',
    icon: <FaGuitar />,
  },
  {
    title: 'Standorte',
    path: '/places',
    icon: <BsPinMapFill />,
  },
  {
    title: 'Medien',
    path: '/media',
    icon: <BsFillImageFill />,
  },
  {
    title: 'News',
    path: '/news',
    icon: <BsNewspaper />,
  },
  {
    title: 'Benachrichtigungen',
    path: '/notifications',
    icon: <BsFillBellFill />,
  },
  {
    title: 'Formulare',
    path: '/forms',
    icon: <FaWpforms />,
  },
];

const Navigation: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [hasSmallHeight] = useMediaQuery('(max-height: 670px)');

  const { signOut } = useAuthenticator();

  return (
    <Box
      h="full"
      w="min-content"
      shadow="md"
      bg="white"
      borderRadius="lg"
      padding="4"
      position="relative"
    >
      <Flex
        w="full"
        justify="center"
      >
        <IconButton
          background="white"
          aria-label="Navigation öffnen"
          onClick={() => setOpen(!open)}
          icon={<VscMenu />}
        />
      </Flex>
      <Flex
        gap={hasSmallHeight ? '2' : '5'}
        mt={hasSmallHeight ? '2' : '10'}
        flexDir="column"
        alignItems={open ? 'start' : 'center'}
      >
        {navItems.map((item) => (
          <Link
            key={item.title}
            to={item.path}
          >
            {open ? (
              <Button
                size={hasSmallHeight ? 'md' : 'lg'}
                bg="white"
                leftIcon={item.icon}
              >
                {item.title}
              </Button>
            ) : (
              <IconButton
                size={hasSmallHeight ? 'md' : 'lg'}
                bg="white"
                aria-label={`wechsle zu ${item.path}`}
                icon={item.icon}
              />
            )}
          </Link>
        ))}
      </Flex>
      <IconButton
        aria-label="ausloggen"
        bg="white"
        size={hasSmallHeight ? 'md' : 'lg'}
        position="absolute"
        bottom={hasSmallHeight ? '2' : '4'}
        onClick={signOut}
        icon={<RiLogoutBoxLine />}
      />
    </Box>
  );
};

export default Navigation;
