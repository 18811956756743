import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import FormsForm from '../../components/forms/FormsForm';
import { CreateFormMutationVariables, useCreateFormMutation } from '../../graphql/forms/generated/createFormMutation.generated';
import { FormsDocument } from '../../graphql/forms/generated/formsQuery.generated';

const CreateForm: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createForm, { loading }] = useCreateFormMutation({
    refetchQueries: [{ query: FormsDocument }],
  });

  const locale = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('defaultLocale')) {
      const item = searchParams.get('defaultLocale');
      if (item) return item;
    }

    return 'de';
  }, []);

  const onSubmit = async (data: CreateFormMutationVariables) => {
    try {
      const newForm = await createForm({
        variables: data,
      });
      toast({
        title: 'Formular erstellt',
        description: 'Das Formular wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      if (newForm.data) {
        navigate(`/forms/${newForm.data.createForm.id}/update`);
      }
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Formular erstellen</Heading>
      <Button
        as={Link}
        to="/forms"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <FormsForm
        locale={locale}
        loading={loading}
        withPublish
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default CreateForm;
