import { useState } from "react";
import { useDebounce } from "./useDebounce";
import { useDidMount } from "./useDidMount";
import { useDidUpdate } from "./useDidUpdate";
var defaultUseDebounceValueOptions = {
    initializeWithNull: false,
};
export var useDebouncedValue = function (value, timeout, options) {
    if (options === void 0) { options = {}; }
    var initializeWithNull = Object.assign({}, defaultUseDebounceValueOptions, options).initializeWithNull;
    var _a = useState(initializeWithNull ? null : value), updatedValue = _a[0], setUpdatedValue = _a[1];
    var debouncedSetUpdatedValue = useDebounce(setUpdatedValue, timeout);
    useDidMount(function () {
        if (initializeWithNull) {
            debouncedSetUpdatedValue(value);
        }
    });
    useDidUpdate(function () {
        debouncedSetUpdatedValue(value);
    }, [value]);
    // No need to add `debouncedSetUpdatedValue ` to dependencies as it is a ref.current.
    // returning both updatedValue and setUpdatedValue (not the debounced version) to instantly update this if  needed.
    return [updatedValue, setUpdatedValue];
};
