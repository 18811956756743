import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Th, useToast,
} from '@chakra-ui/react';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { AllNewsQueryVariables, useAllNewsQuery } from '../../graphql/news/generated/allNewsQuery.generated';
import { useDeleteNewsMutation } from '../../graphql/news/generated/deleteNewsMutation.generated';
import { CreateNotificationMutationVariables, useCreateNotificationMutation } from '../../graphql/notifications/generated/createNotification.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('newsVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const News: FC = () => {
  const toast = useToast();
  const [variables, setVariables] = useState<AllNewsQueryVariables>(getDefaultVariables());

  const onVariablesChange = (params: Partial<AllNewsQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const [createNotification] = useCreateNotificationMutation();

  const { data, loading, refetch } = useAllNewsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    localStorage.setItem('newsVariables', JSON.stringify(variables));
  }, [variables]);

  const [deleteNews] = useDeleteNewsMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteNews({
        variables: {
          id,
        },
      });
      toast({
        title: 'News wurde gelöscht',
        description: 'Die ausgewählte News wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onNotification = async (id: string) => {
    if (data && data.allNews.docs.length > 0) {
      const news = data.allNews.docs.find((n) => n.id === id);
      if (news) {
        const notification: CreateNotificationMutationVariables = {};
        if (news.locale === 'de') {
          notification.de = {
            title: news.title,
            body: news.description.replace(/(<([^>]+)>)/gi, ''),
            mainEvent: news.mainEvent ? news.mainEvent.id : null,
          };
        } else {
          notification.en = {
            title: news.title,
            body: news.description.replace(/(<([^>]+)>)/gi, ''),
            mainEvent: news.mainEvent ? news.mainEvent.id : null,
          };
        }
        await createNotification({
          variables: notification,
        });
        toast({
          title: 'Benachrichtigung gesendet!',
          description: 'Benachrichtigung wurde gesendet',
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('newsVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.allNews.docs}
        pathPrefix="news"
        page={data?.allNews.page}
        totalPages={data?.allNews.totalPages}
        loading={loading}
        onDelete={onDelete}
        onFilterClear={clearFilter}
        onNotification={onNotification}
        tableHead={(
          <>
            <Th>Name</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default News;
