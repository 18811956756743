import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMainEventMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  locale: Types.Scalars['String'];
  logo?: Types.InputMaybe<Types.Scalars['ID']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
  content?: Types.InputMaybe<Types.Scalars['JSON']>;
  showScheduleLink?: Types.InputMaybe<Types.Scalars['Boolean']>;
  disableInfoAndProgram?: Types.InputMaybe<Types.Scalars['Boolean']>;
  mainPageId?: Types.InputMaybe<Types.Scalars['String']>;
  position?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CreateMainEventMutation = { __typename?: 'Mutation', createMainEvent: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null } };


export const CreateMainEventDocument = gql`
    mutation CreateMainEvent($name: String!, $description: String!, $locale: String!, $logo: ID, $status: StatusTypes, $content: JSON, $showScheduleLink: Boolean, $disableInfoAndProgram: Boolean, $mainPageId: String, $position: Int) {
  createMainEvent(
    name: $name
    description: $description
    locale: $locale
    status: $status
    logo: $logo
    content: $content
    position: $position
    disableInfoAndProgram: $disableInfoAndProgram
    mainPageId: $mainPageId
    showScheduleLink: $showScheduleLink
  ) {
    ...mainEvent
  }
}
    ${MainEventFragmentDoc}`;
export type CreateMainEventMutationFn = Apollo.MutationFunction<CreateMainEventMutation, CreateMainEventMutationVariables>;

/**
 * __useCreateMainEventMutation__
 *
 * To run a mutation, you first call `useCreateMainEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMainEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMainEventMutation, { data, loading, error }] = useCreateMainEventMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      logo: // value for 'logo'
 *      status: // value for 'status'
 *      content: // value for 'content'
 *      showScheduleLink: // value for 'showScheduleLink'
 *      disableInfoAndProgram: // value for 'disableInfoAndProgram'
 *      mainPageId: // value for 'mainPageId'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useCreateMainEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateMainEventMutation, CreateMainEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMainEventMutation, CreateMainEventMutationVariables>(CreateMainEventDocument, options);
      }
export type CreateMainEventMutationHookResult = ReturnType<typeof useCreateMainEventMutation>;
export type CreateMainEventMutationResult = Apollo.MutationResult<CreateMainEventMutation>;
export type CreateMainEventMutationOptions = Apollo.BaseMutationOptions<CreateMainEventMutation, CreateMainEventMutationVariables>;