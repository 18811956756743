import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PageFragmentDoc } from '../../fragments/generated/page.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePageMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeletePageMutation = { __typename?: 'Mutation', deletePage: { __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string } };


export const DeletePageDocument = gql`
    mutation DeletePage($id: ID!) {
  deletePage(id: $id) {
    ...page
  }
}
    ${PageFragmentDoc}`;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
      }
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;