import {
  Button, FormControl, FormLabel, Heading, Switch, useToast,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import PagesForm from '../../components/pages/PagesForm';
import { CreatePageMutationVariables } from '../../graphql/pages/generated/createPageMutation.generated';
import { usePageQuery } from '../../graphql/pages/generated/pageQuery.generated';
import { useUpdatePageMutation } from '../../graphql/pages/generated/updatePageMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdatePage: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { eventId, pageId, locale } = useParams() as {
    eventId: string,
    pageId: string,
    locale: string,
  };

  const [updatePage, { loading }] = useUpdatePageMutation();

  const { data: pageResponse } = usePageQuery({
    variables: {
      id: pageId,
    },
  });

  const onSubmit = async (data: CreatePageMutationVariables) => {
    try {
      await updatePage({
        variables: {
          id: pageId,
          ...data,
        },
      });
      toast({
        title: 'Seite geändert',
        description: 'Die Seite wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate(`/main-events/${eventId}/update`);
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updatePage({
        variables: {
          id: pageId,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Seite geändert',
        description: 'Die Seite wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Seite ändern</Heading>
      <Button
        as={Link}
        to={`/main-events/${eventId}/update`}
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {pageResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={pageResponse.page.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <PagesForm
            eventId={eventId}
            locale={locale}
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={pageResponse.page}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdatePage;
