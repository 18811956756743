import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type PageFragment = { __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string };

export const PageFragmentDoc = gql`
    fragment page on Page {
  id
  name
  status
  locale
  description
  content
  createdAt
  order
  updatedAt
}
    `;