import React, { FC } from 'react';
import { TableData } from '../../../models/table';
import ArtistColumn from './ArtistColumn';
import MainEventColumn from './MainEventColumn';
import MediaColumn from './MediaColumn';
import PlaceColumn from './PlaceColumn';
import PageColumn from './PageColumn';
import NewsColumn from './NewsColumn';
import EventsColumn from './EventsColumn';
import NotificationColumn from './NotificationColumn';
import ScheduledNotificationColumn from './ScheduledNotificationColumn';
import FormColumn from './FormColumn';

const Column: FC<{ item: TableData }> = ({ item }) => {
  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Artist') {
    return <ArtistColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Media') {
    return <MediaColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Place') {
    return <PlaceColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'MainEvent') {
    return <MainEventColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Page') {
    return <PageColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'News') {
    return <NewsColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Event') {
    return <EventsColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Notification') {
    return <NotificationColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'Form') {
    return <FormColumn item={item} />;
  }

  // eslint-disable-next-line no-underscore-dangle
  if (item.__typename === 'ScheduledNotification') {
    return <ScheduledNotificationColumn item={item} />;
  }

  return null;
};

export default Column;
