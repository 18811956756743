import {
  Select, Text,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { IHeadlineBlock } from '../../../models/blocks';
import TextInput from '../../TextInput';

interface HeadlineProps {
  field: IHeadlineBlock,
  onChange: (value: IHeadlineBlock) => void,
}

const Headline: FC<HeadlineProps> = ({ field, onChange }) => (
  <>
    <Text
      mb="2"
      fontWeight="bold"
    >
      Art:
    </Text>
    <Select
      onChange={(e) => onChange({ ...field, level: e.target.value as '1' | '2' })}
      placeholder="Überschrifttyp auswählen"
      mb={4}
      defaultValue={field.level}
    >
      <option value="1">Überschrift 1</option>
      <option value="2">Überschrift 2</option>
    </Select>
    <TextInput
      value={field.content || ''}
      onChange={(e) => onChange({ ...field, content: e.target.value })}
    >
      Text:
    </TextInput>
  </>
);

export default Headline;
