import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PageFragmentDoc } from '../../fragments/generated/page.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePageMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  locale: Types.Scalars['String'];
  event: Types.Scalars['ID'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  content: Types.Scalars['JSON'];
  order?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CreatePageMutation = { __typename?: 'Mutation', createPage: { __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string } };


export const CreatePageDocument = gql`
    mutation CreatePage($name: String!, $description: String!, $locale: String!, $event: ID!, $status: StatusTypes, $content: JSON!, $order: Int) {
  createPage(
    name: $name
    description: $description
    locale: $locale
    status: $status
    event: $event
    order: $order
    content: $content
  ) {
    ...page
  }
}
    ${PageFragmentDoc}`;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      event: // value for 'event'
 *      status: // value for 'status'
 *      content: // value for 'content'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useCreatePageMutation(baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
      }
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;