import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { FormFragmentDoc } from '../../fragments/generated/form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteFormMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteFormMutation = { __typename?: 'Mutation', deleteForm: { __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string } };


export const DeleteFormDocument = gql`
    mutation DeleteForm($id: ID!) {
  deleteForm(id: $id) {
    ...form
  }
}
    ${FormFragmentDoc}`;
export type DeleteFormMutationFn = Apollo.MutationFunction<DeleteFormMutation, DeleteFormMutationVariables>;

/**
 * __useDeleteFormMutation__
 *
 * To run a mutation, you first call `useDeleteFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormMutation, { data, loading, error }] = useDeleteFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFormMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormMutation, DeleteFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormMutation, DeleteFormMutationVariables>(DeleteFormDocument, options);
      }
export type DeleteFormMutationHookResult = ReturnType<typeof useDeleteFormMutation>;
export type DeleteFormMutationResult = Apollo.MutationResult<DeleteFormMutation>;
export type DeleteFormMutationOptions = Apollo.BaseMutationOptions<DeleteFormMutation, DeleteFormMutationVariables>;