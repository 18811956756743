import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import PagesForm from '../../components/pages/PagesForm';
import { MainEventDocument } from '../../graphql/mainEvents/generated/mainEventQuery.generated';
import { CreatePageMutationVariables, useCreatePageMutation } from '../../graphql/pages/generated/createPageMutation.generated';

const CreatePage: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createPage, { loading }] = useCreatePageMutation({
    refetchQueries: [{ query: MainEventDocument }],
  });

  const { eventId, locale } = useParams() as {
    eventId: string,
    locale: string,
  };

  const onSubmit = async (data: CreatePageMutationVariables) => {
    try {
      await createPage({
        variables: data,
      });
      toast({
        title: 'Seite erstellt',
        description: 'Die Seite wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate(`/main-events/${eventId}/update`);
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Seite erstellen</Heading>
      <Button
        as={Link}
        to={`/main-events/${eventId}/update`}
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <PagesForm
        eventId={eventId}
        locale={locale}
        loading={loading}
        withPublish
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default CreatePage;
