import React, { FC } from 'react';
import { IParagraphBlock } from '../../../models/blocks';
import Richtext from '../../Richtext';

interface ParagraphProps {
  field: IParagraphBlock,
  onChange: (value: IParagraphBlock) => void,
}

const Paragraph: FC<ParagraphProps> = ({ field, onChange }) => (
  <Richtext
    value={field.content || ''}
    onChange={(newValue) => onChange({ ...field, content: newValue })}
  >
    Text:
  </Richtext>
);

export default Paragraph;
