import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Th, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { MainEventsQueryVariables, useMainEventsQuery } from '../../graphql/mainEvents/generated/mainEvents.generated';
import { useDeleteMainEventMutation } from '../../graphql/mainEvents/generated/deleteMainEventMutation.generated';
import { useCopyMainEventMutation } from '../../graphql/mainEvents/generated/copyMainEventMutation.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('mainEventVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const MainEvents: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [variables, setVariables] = useState<MainEventsQueryVariables>(getDefaultVariables());

  const onVariablesChange = (params: Partial<MainEventsQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { data, loading, refetch } = useMainEventsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    localStorage.setItem('mainEventVariables', JSON.stringify(variables));
  }, [variables]);

  const [deleteMainEvent] = useDeleteMainEventMutation();
  const [copyMainEvent] = useCopyMainEventMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteMainEvent({
        variables: {
          id,
        },
      });
      toast({
        title: 'Event wurde gelöscht',
        description: 'Das ausgewählte Event wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onCopy = async (id: string, locale: string) => {
    try {
      const newMainEvent = await copyMainEvent({
        variables: {
          id,
          locale,
        },
      });
      toast({
        title: 'Event wurde erstellt',
        description: 'Das ausgewählte Event wurde erstellt',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      if (newMainEvent.data) {
        navigate(`/main-events/${newMainEvent.data.copyMainEvent.id}/update`);
      }
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('mainEventVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.mainEvents.docs}
        pathPrefix="main-events"
        page={data?.mainEvents.page}
        totalPages={data?.mainEvents.totalPages}
        loading={loading}
        onDelete={onDelete}
        onCopy={onCopy}
        onFilterClear={clearFilter}
        tableHead={(
          <>
            <Th>Logo</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default MainEvents;
