import { Box, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import ReactQuill from 'react-quill';

interface RichtextProps {
  isInvalid?: boolean,
  onChange?: (value: string) => void,
  value?: string,
}

const Richtext: FC<RichtextProps> = ({
  children, isInvalid, value, onChange,
}) => {
  const onEditorContentChange = (newContent: string) => {
    // quill for some reason if nothing is in the box writes <p><br></p>
    const onlyText = newContent.replace(/(<([^>]+)>)/ig, '');

    if (onChange) {
      onChange(onlyText ? newContent : '');
    }
  };

  return (
    <Box
      className={isInvalid ? 'invalid-quill' : ''}
    >
      <Text
        w="full"
        fontWeight="bold"
        mb="8px"
      >
        {children}
      </Text>
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['clean'],
          ],
        }}
        value={value}
        onChange={onEditorContentChange}
      />
    </Box>
  );
};

export default Richtext;
