import {
  Button, Checkbox, Flex, Select,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { omit, pick } from 'lodash';
import TextInput from '../TextInput';
import Richtext from '../Richtext';
import { CreateNewsMutationVariables } from '../../graphql/news/generated/createNewsMutation.generated';
import { NewsQuery } from '../../graphql/news/generated/newsQuery.generated';
import RelationshipInput from '../RelationshipInput';
import { MainEventsDocument } from '../../graphql/mainEvents/generated/mainEvents.generated';
import { CreateNotificationMutationVariables, useCreateNotificationMutation } from '../../graphql/notifications/generated/createNotification.generated';

interface FormValues {
  title: string,
  description: string,
  mainEvent?: NewsQuery['news']['mainEvent'],
  locale: string,
  sendNotification?: boolean,
}

interface NewsFormProps {
  onSubmit: (data: CreateNewsMutationVariables) => void,
  loading?: boolean,
  defaultValues?: NewsQuery['news'],
  noNotification?: boolean,
}

const NewsForm: FC<NewsFormProps> = ({
  onSubmit, loading, defaultValues, noNotification,
}) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control, watch,
  } = useForm<FormValues>({
    defaultValues: defaultValues ? pick(defaultValues, ['locale', 'title', 'description', 'mainEvent']) : undefined,
  });

  const [createNotification, { loading: notificationLoading }] = useCreateNotificationMutation();

  const onSave = async (data: FormValues) => {
    if (data.sendNotification && !noNotification) {
      const notification: CreateNotificationMutationVariables = {};
      if (data.locale === 'de') {
        notification.de = {
          title: data.title,
          body: data.description.replace(/(<([^>]+)>)/gi, ''),
          mainEvent: data.mainEvent ? data.mainEvent.id : null,
        };
      } else {
        notification.en = {
          title: data.title,
          body: data.description.replace(/(<([^>]+)>)/gi, ''),
          mainEvent: data.mainEvent ? data.mainEvent.id : null,
        };
      }
      await createNotification({
        variables: notification,
      });
    }
    await onSubmit(omit({
      ...data,
      mainEvent: data.mainEvent ? data.mainEvent.id : null,
    }, ['sendNotification']));

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale', { required: !defaultValues })}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <TextInput
          isInvalid={!!errors.title}
          {...register('title', { required: !defaultValues })}
          placeholder="Titel..."
        >
          Titel: *
        </TextInput>
        <Controller
          render={
            ({ field }) => (
              <Richtext
                isInvalid={!!errors.description}
                value={field.value}
                onChange={field.onChange}
              >
                Beschreibung: *
              </Richtext>
            )
          }
          rules={{ required: true }}
          control={control}
          name="description"
        />
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={MainEventsDocument}
                isInvalid={!!errors.mainEvent}
                value={field.value as any}
                onChange={field.onChange}
                locale={watch('locale') || 'de'}
              >
                Event:
              </RelationshipInput>
            )
          }
          control={control}
          name="mainEvent"
        />
        {!noNotification && (
          <Checkbox
            {...register('sendNotification')}
          >
            Als Benachrichtigung senden
          </Checkbox>
        )}
      </Flex>
      <Button
        mt="30px"
        type="submit"
        colorScheme="teal"
        isLoading={loading || notificationLoading}
      >
        Speichern
      </Button>
    </form>
  );
};

export default NewsForm;
