import React, {
  ChangeEvent,
  FC, useEffect, useState,
} from 'react';
import {
  Box,
  Select,
  Th, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { omit, pick } from 'lodash';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { EventsQueryVariables, useEventsQuery } from '../../graphql/events/generated/eventsQuery.generated';
import { useDeleteEventMutation } from '../../graphql/events/generated/deleteEventMutation.generated';
import { useCreateEventMutation } from '../../graphql/events/generated/createEventMutation.generated';
import { useMainEventsQuery } from '../../graphql/mainEvents/generated/mainEvents.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
  sort: '-createdAt',
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('eventVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const Events: FC = () => {
  const pickValues: string[] = [
    'name',
    'description',
    'image',
    'locale',
    'start',
    'end',
    'fullDay',
    'place',
    'artist',
    'mainEvent',
    'location',
    'coordinates',
  ];
  const toast = useToast();
  const navigate = useNavigate();
  const [createEvent] = useCreateEventMutation();
  const [variables, setVariables] = useState<EventsQueryVariables>(getDefaultVariables());

  const { data: mainEventData } = useMainEventsQuery({
    variables: {
      term: '',
      offset: 0,
      locale: variables.locale,
      limit: 100,
    },
    fetchPolicy: 'cache-and-network',
  });
  const onVariablesChange = (params: Partial<EventsQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { data, loading, refetch } = useEventsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [deleteEvent] = useDeleteEventMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteEvent({
        variables: {
          id,
        },
      });
      toast({
        title: 'Veranstaltung wurde gelöscht',
        description: 'Die Veranstaltung wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onMainEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      onVariablesChange({
        mainEvent: e.target.value,
      });
    } else {
      onVariablesChange({
        mainEvent: undefined,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem('eventVariables', JSON.stringify(variables));
  }, [variables]);

  const onCopy = async (eventId: string, locale: string) => {
    if (data && data.events && data.events.docs.length > 0) {
      const event = data.events.docs.find((e) => e.id === eventId);
      if (event) {
        const parsedEvent = {
          ...pick(event, pickValues),
          fullDay: event.fullDay || false,
          place: (event.place && event.place.locale === locale) ? event.place.id : null,
          artist: (event.artist && event.artist.locale === locale) ? event.artist.id : null,
          image: event.image ? event.image.id : null,
          mainEvent: (event.mainEvent && event.mainEvent.locale === locale)
            ? event.mainEvent.id
            : null,
          coordinates: event.coordinates ? pick(event.coordinates, ['lat', 'lng', 'name']) : null,
          location: event.location ? omit(event.location, ['__typename']) : null,
        };
        const newEvent = await createEvent({
          variables: {
            ...parsedEvent as any,
            locale,
          },
        });
        toast({
          title: 'Veranstaltung erstellt',
          description: 'Veranstaltung wurde erstellt',
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
        if (newEvent.data) {
          navigate(`/events/${newEvent.data.createEvent.id}/update`);
        }
      }
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('eventVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.events.docs}
        pathPrefix="events"
        page={data?.events.page}
        totalPages={data?.events.totalPages}
        loading={loading}
        onDelete={onDelete}
        onCopy={onCopy}
        onFilterClear={clearFilter}
        additionalFilter={(
          <Box>
            <Select
              onChange={onMainEventChange}
              placeholder="Alle Events"
              value={variables.mainEvent as any}
            >
              {mainEventData?.mainEvents.docs.map((mainEvent) => (
                <option key={mainEvent.id} value={mainEvent.id}>{mainEvent.name}</option>
              ))}
            </Select>
          </Box>
        )}
        tableHead={(
          <>
            <Th>Bild</Th>
            <Th>Name</Th>
            <Th>Startdatum</Th>
            <Th>Status</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default Events;
