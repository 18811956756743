import {
  Box,
  Button, Checkbox, Flex, Select, Text,
} from '@chakra-ui/react';
import React, { BaseSyntheticEvent, FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { omit, pick } from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import { parseISO } from 'date-fns';
import de from 'date-fns/locale/de';
import TextInput from '../TextInput';
import Richtext from '../Richtext';
import RelationshipInput from '../RelationshipInput';
import { MainEventsDocument } from '../../graphql/mainEvents/generated/mainEvents.generated';
import { EventQuery } from '../../graphql/events/generated/eventQuery.generated';
import { CreateEventMutationVariables } from '../../graphql/events/generated/createEventMutation.generated';
import { LocationInput, StatusTypes } from '../../types/graphql.generated';
import MediaInput from '../MediaInput';
import CoordinatesInput from '../CoordinatesInput';
import { PlacesDocument } from '../../graphql/places/generated/placesQuery.generated';
import { ArtistsDocument } from '../../graphql/artists/generated/artistsQuery.generated';
import { FormsDocument } from '../../graphql/forms/generated/formsQuery.generated';

registerLocale('de-DE', de);

type Event = EventQuery['event'];

interface FormValues {
  name: string,
  description: string,
  image: Event['image'],
  locale: string,
  start: string,
  end: string,
  fullDay: boolean,
  place?: Event['place'],
  form?: Event['form'],
  artist?: Event['artist'],
  mainEvent?: Event['mainEvent'],
  location?: LocationInput | null,
  coordinates?: {
    lat: number,
    lng: number,
    name?: string,
  } | null,
}

interface EventFormProps {
  onSubmit: (data: CreateEventMutationVariables) => void,
  loading?: boolean,
  defaultValues?: Event,
  persist?: boolean,
  withPublish?: boolean,
}

const EventForm: FC<EventFormProps> = ({
  onSubmit, loading, defaultValues, persist = false, withPublish = false,
}) => {
  const pickValues: string[] = [
    'name',
    'description',
    'image',
    'locale',
    'start',
    'end',
    'fullDay',
    'form',
    'place',
    'artist',
    'mainEvent',
    'location',
    'coordinates',
  ];

  const getDefaultValues = () => {
    const values: any = defaultValues ? pick(defaultValues as any, pickValues) : undefined;
    // const localSave = localStorage.getItem('eventForm');
    // if (localSave && persist) {
    //   values = JSON.parse(localSave);
    // } else {
    //   values = defaultValues ? pick(defaultValues as any, pickValues) : undefined;
    // }

    if (values && !values.locale) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has('defaultLocale')) {
        values.locale = searchParams.get('defaultLocale');
      }
    }

    return values;
  };
  const {
    register, handleSubmit, formState: { errors }, reset, control, watch,
  } = useForm<FormValues>({
    defaultValues: getDefaultValues(),
  });

  const formValues = watch();
  useEffect(() => {
    if (persist) {
      localStorage.setItem('eventForm', JSON.stringify(formValues));
    }
  }, [formValues, persist]);

  const onSave = async (data: FormValues, e?: BaseSyntheticEvent) => {
    const buttonType = (e?.nativeEvent as any).submitter?.value || 'draft';

    await onSubmit({
      ...data,
      place: data.place ? data.place.id : null,
      artist: data.artist ? data.artist.id : null,
      form: data.form ? data.form.id : null,
      image: data.image ? data.image.id : null,
      mainEvent: data.mainEvent ? data.mainEvent.id : null,
      coordinates: data.coordinates ? pick(data.coordinates, ['lat', 'lng', 'name']) : null,
      location: data.location ? omit(data.location, ['__typename']) : null,
      ...(withPublish ? { status: buttonType === 'publish' ? StatusTypes.PUBLISHED : StatusTypes.DRAFT } : {}),
    });
    localStorage.removeItem('eventForm');
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale', { required: !defaultValues })}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <TextInput
          isInvalid={!!errors.name}
          {...register('name', { required: !defaultValues })}
          placeholder="Name..."
        >
          Name: *
        </TextInput>
        <Controller
          render={
            ({ field }) => (
              <Richtext
                isInvalid={!!errors.description}
                value={field.value}
                onChange={field.onChange}
              >
                Beschreibung:
              </Richtext>
            )
          }
          control={control}
          name="description"
        />
        <Controller
          render={
            ({ field }) => (
              <Box>
                <Text
                  w="full"
                  fontWeight="bold"
                  mb="-15px"
                >
                  Start:

                </Text>
                <DatePicker
                  showTimeSelect
                  locale="de-DE"
                  timeCaption="Zeit"
                  timeIntervals={15}
                  dateFormat="EEEE d. LLLL yyyy HH:mm"
                  customInput={<TextInput />}
                  selected={field.value ? parseISO(field.value) : null}
                  onChange={(newDate) => field.onChange(newDate?.toISOString())}
                />
              </Box>
            )
          }
          control={control}
          name="start"
        />
        <Controller
          render={
            ({ field }) => (
              <Box>
                <Text
                  w="full"
                  fontWeight="bold"
                  mb="-15px"
                >
                  Ende:

                </Text>
                <DatePicker
                  showTimeSelect
                  locale="de-DE"
                  timeCaption="Zeit"
                  timeIntervals={15}
                  dateFormat="EEEE d. LLLL yyyy HH:mm"
                  customInput={<TextInput />}
                  selected={field.value ? parseISO(field.value) : null}
                  onChange={(newDate) => field.onChange(newDate?.toISOString())}
                />
              </Box>
            )
          }
          control={control}
          name="end"
        />
        <Checkbox
          {...register('fullDay')}
        >
          Ganzer Tag
        </Checkbox>
        <Controller
          render={
            ({ field }) => (
              <MediaInput
                isInvalid={!!errors.image}
                value={field.value || undefined}
                locale={watch('locale')}
                onChange={field.onChange}
              >
                {/* Bild: * */}
                Bild:
              </MediaInput>
            )
          }
          control={control}
          name="image"
        />
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={ArtistsDocument}
                isInvalid={!!errors.artist}
                value={field.value as any}
                onChange={field.onChange}
                locale={watch('locale') || 'de'}
              >
                Künstler:in:
              </RelationshipInput>
            )
          }
          control={control}
          name="artist"
        />
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={FormsDocument}
                isInvalid={!!errors.form}
                value={field.value as any}
                onChange={field.onChange}
                locale={watch('locale') || 'de'}
              >
                Formular:
              </RelationshipInput>
            )
          }
          control={control}
          name="form"
        />
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={PlacesDocument}
                isInvalid={!!errors.place}
                value={field.value as any}
                onChange={field.onChange}
                locale={watch('locale') || 'de'}
              >
                Standort:
              </RelationshipInput>
            )
          }
          control={control}
          name="place"
        />
        <Controller
          render={
            ({ field }) => (
              <RelationshipInput
                document={MainEventsDocument}
                isInvalid={!!errors.mainEvent}
                value={field.value as any}
                onChange={field.onChange}
                locale={watch('locale') || 'de'}
              >
                Event:
              </RelationshipInput>
            )
          }
          control={control}
          name="mainEvent"
        />
        <Box
          border="solid"
          borderColor="gray.300"
          p="4"
          borderRadius="md"
        >
          <Text
            fontSize="xl"
            fontWeight="bold"
            mb="2"
          >
            Location:
          </Text>
          <Flex
            flexDirection="column"
            gap="5"
          >
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.name')}
              placeholder="Location-Name..."
            >
              Location-Name:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.street')}
              placeholder="Name..."
            >
              Straße:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.postalCode')}
              placeholder="Postleitzahl..."
            >
              Postleitzahl:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.city')}
              placeholder="Stadt..."
            >
              Stadt:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.email')}
              placeholder="E-Mail..."
            >
              E-Mail:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.phone')}
              placeholder="Telefon..."
            >
              Telefon:
            </TextInput>
            <TextInput
              isInvalid={!!errors.location}
              {...register('location.website')}
              placeholder="Website..."
            >
              Website:
            </TextInput>

            <Controller
              render={
                ({ field }) => (
                  <Richtext
                    value={field.value || ''}
                    onChange={field.onChange}
                  >
                    Information (zB. Öffnungszeiten):
                  </Richtext>
                )
              }
              control={control}
              name="location.information"
            />
          </Flex>
        </Box>
        <Controller
          render={
            ({ field }) => (
              <CoordinatesInput
                withAdress
                isInvalid={!!errors.coordinates}
                value={field.value}
                onChange={field.onChange}
              >
                Ort:
              </CoordinatesInput>
            )
          }
          control={control}
          name="coordinates"
        />
      </Flex>
      <Flex gap={4}>
        <Button
          mt="30px"
          type="submit"
          value="draft"
          colorScheme="teal"
          isLoading={loading}
        >
          Speichern
        </Button>
        {withPublish && (
          <Button
            mt="30px"
            value="publish"
            type="submit"
            isLoading={loading}
          >
            Speichern & Veröffentlichen
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default EventForm;
