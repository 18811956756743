import {
  Flex, InputGroup, InputLeftElement, Text, Textarea, TextareaProps,
} from '@chakra-ui/react';
import React, {
  ForwardRefRenderFunction, ReactNode, forwardRef,
} from 'react';

interface TextareaInputProps extends TextareaProps {
  icon?: ReactNode,
}

const TextareaInput: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaInputProps> = (
  {
    children,
    icon,
    ...rest
  },
  ref,
) => (
  <Flex
    w="full"
    direction="column"
    gap="2"
  >
    <Text
      w="full"
      fontWeight="bold"
    >
      {children}
    </Text>
    <InputGroup>
      {icon && (
        <InputLeftElement
          pointerEvents="none"
        >
          {icon}
        </InputLeftElement>
      )}
      <Textarea
        {...rest}
        ref={ref}
      />
    </InputGroup>
  </Flex>
);

export default forwardRef<HTMLTextAreaElement, TextareaInputProps>(TextareaInput);
