import {
  Box, Button, Icon, IconButton, useDisclosure,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { GoPlus } from 'react-icons/go';
import { IoMdTrash } from 'react-icons/io';
import { v4 as uuidv4 } from 'uuid';
import {
  FAQItem, IFAQBlock,
} from '../../../models/blocks';
import AlertDialogWrapper from '../../AlertDialogWrapper';
import Richtext from '../../Richtext';
import TextInput from '../../TextInput';

interface FAQProps {
  field: IFAQBlock,
  onChange: (value: IFAQBlock) => void,
}

const FAQ: FC<FAQProps> = ({ field, onChange }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onItemChange = (item: FAQItem) => {
    onChange({
      ...field,
      content: field.content ? field.content.map((oldItem) => {
        if (oldItem.id === item.id) return item;

        return oldItem;
      }) : [],
    });
  };

  const onItemDelete = (id: string) => {
    onChange({
      ...field,
      content: field.content ? field.content.filter((item) => item.id !== id) : [],
    });
    onClose();
  };

  const onItemAdd = () => {
    onChange({
      ...field,
      content: [
        ...(field.content || []),
        {
          id: uuidv4(),
          title: '',
          content: '',
        },
      ],
    });
  };

  return (
    <Box>
      {field.content?.map((item) => (
        <Box
          key={item.id}
          mb="4"
          border="solid"
          borderColor="gray.300"
          borderRadius="md"
          p="4"
          position="relative"
        >
          <TextInput
            mb="4"
            value={item.title}
            onChange={(e) => onItemChange({ ...item, title: e.target.value })}
          >
            Titel
          </TextInput>
          <Richtext
            value={item.content}
            onChange={(newValue) => onItemChange({ ...item, content: newValue })}
            isInvalid={false}
          >
            Text
          </Richtext>
          <Box
            position="absolute"
            top="10px"
            right="10px"
          >
            <IconButton
              colorScheme="teal"
              size="xs"
              onClick={onOpen}
              aria-label="FAQ Element löschen"
              icon={<Icon as={IoMdTrash} />}
            />
            <AlertDialogWrapper
              isOpen={isOpen}
              onClose={onClose}
              onAccept={() => onItemDelete(item.id)}
              title="Löschen?"
              description="Wollen sie die ausgewählte Frage wirklich löschen?"
              buttonText="Löschen"
            />
          </Box>
        </Box>
      ))}
      <Button
        leftIcon={<GoPlus />}
        colorScheme="teal"
        onClick={onItemAdd}
      >
        Frage hinzufügen
      </Button>
    </Box>
  );
};

export default FAQ;
