import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type NewsFragment = { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string };

export type NewsWithEventFragment = { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string } | null };

export const NewsFragmentDoc = gql`
    fragment news on News {
  id
  title
  description
  locale
  createdAt
  updatedAt
}
    `;
export const NewsWithEventFragmentDoc = gql`
    fragment newsWithEvent on News {
  id
  title
  description
  mainEvent {
    id
    name
    status
    locale
    description
    createdAt
    updatedAt
  }
  locale
  createdAt
  updatedAt
}
    `;