import {
  Button, FormControl, FormLabel, Heading, Switch, useToast,
} from '@chakra-ui/react';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EventForm from '../../components/event/EventForm';
import Layout from '../../components/Layout';
import { CreateEventMutationVariables } from '../../graphql/events/generated/createEventMutation.generated';
import { useEventQuery } from '../../graphql/events/generated/eventQuery.generated';
import { useUpdateEventMutation } from '../../graphql/events/generated/updateEventMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdateEvent: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updateEvent, { loading }] = useUpdateEventMutation();

  const { data: eventResponse } = useEventQuery({
    variables: {
      id,
    },
  });

  const onSubmit = async (data: CreateEventMutationVariables) => {
    try {
      await updateEvent({
        variables: {
          id,
          ...data,
        },
      });
      toast({
        title: 'Veranstaltung geändert',
        description: 'Veranstaltung wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/events');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updateEvent({
        variables: {
          id,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Veranstaltung geändert',
        description: 'Veranstaltung wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Veranstaltung ändern</Heading>
      <Button
        as={Link}
        to="/events"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {eventResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={eventResponse.event.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <EventForm
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={eventResponse.event}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdateEvent;
