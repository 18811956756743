import React, { FC } from 'react';
import { INumberBlock } from '../../../models/forms';
import TextInput from '../../TextInput';

interface NumberProps {
  field: INumberBlock,
  onChange: (value: INumberBlock) => void,
}

const Number: FC<NumberProps> = ({ field, onChange }) => (
  <>
    <TextInput
      value={field.label || ''}
      onChange={(e) => onChange({ ...field, label: e.target.value })}
    >
      Titel:
    </TextInput>
    <TextInput
      value={field.placeholder || ''}
      onChange={(e) => onChange({ ...field, placeholder: e.target.value })}
    >
      Platzhalter:
    </TextInput>
  </>
);

export default Number;
