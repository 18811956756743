import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { FormFragmentDoc } from '../../fragments/generated/form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormsQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  locale: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;


export type FormsQuery = { __typename?: 'Query', forms: { __typename?: 'FormPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string }> } };


export const FormsDocument = gql`
    query Forms($term: String!, $status: StatusTypes, $locale: String!, $limit: Int!, $offset: Int!) {
  forms(
    term: $term
    status: $status
    locale: $locale
    limit: $limit
    offset: $offset
  ) {
    docs {
      ...form
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    ${FormFragmentDoc}`;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFormsQuery(baseOptions: Apollo.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
      }
export function useFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, options);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsQueryResult = Apollo.QueryResult<FormsQuery, FormsQueryVariables>;