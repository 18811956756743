import React, { FC } from 'react';
import { IMapBlock } from '../../../models/blocks';
import CoordinatesInput from '../../CoordinatesInput';

interface MapProps {
  field: IMapBlock,
  onChange: (value: IMapBlock) => void,
}

const Map: FC<MapProps> = ({ field, onChange }) => (
  <CoordinatesInput
    isInvalid={false}
    value={field.coordinates}
    onChange={(coords) => onChange({ ...field, coordinates: coords })}
  >
    Ort:
  </CoordinatesInput>
);

export default Map;
