import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PlaceFragmentDoc } from '../../fragments/generated/place.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePlaceMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  coordinates?: Types.InputMaybe<Types.CoordinateInput>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
  image?: Types.InputMaybe<Types.Scalars['ID']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
}>;


export type UpdatePlaceMutation = { __typename?: 'Mutation', updatePlace: { __typename?: 'Place', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null, coordinates?: { __typename?: 'Coordinate', lat?: number | null, lng?: number | null, name?: string | null } | null } };


export const UpdatePlaceDocument = gql`
    mutation UpdatePlace($id: ID!, $name: String, $description: String, $coordinates: CoordinateInput, $locale: String, $image: ID, $status: StatusTypes) {
  updatePlace(
    id: $id
    input: {name: $name, description: $description, locale: $locale, image: $image, status: $status, coordinates: $coordinates}
  ) {
    ...place
  }
}
    ${PlaceFragmentDoc}`;
export type UpdatePlaceMutationFn = Apollo.MutationFunction<UpdatePlaceMutation, UpdatePlaceMutationVariables>;

/**
 * __useUpdatePlaceMutation__
 *
 * To run a mutation, you first call `useUpdatePlaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaceMutation, { data, loading, error }] = useUpdatePlaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      coordinates: // value for 'coordinates'
 *      locale: // value for 'locale'
 *      image: // value for 'image'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePlaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaceMutation, UpdatePlaceMutationVariables>(UpdatePlaceDocument, options);
      }
export type UpdatePlaceMutationHookResult = ReturnType<typeof useUpdatePlaceMutation>;
export type UpdatePlaceMutationResult = Apollo.MutationResult<UpdatePlaceMutation>;
export type UpdatePlaceMutationOptions = Apollo.BaseMutationOptions<UpdatePlaceMutation, UpdatePlaceMutationVariables>;