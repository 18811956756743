import React, { FC } from 'react';
import { IEmailBlock } from '../../../models/forms';
import TextInput from '../../TextInput';

interface EmailProps {
  field: IEmailBlock,
  onChange: (value: IEmailBlock) => void,
}

const Email: FC<EmailProps> = ({ field, onChange }) => (
  <>
    <TextInput
      value={field.label || ''}
      onChange={(e) => onChange({ ...field, label: e.target.value })}
    >
      Titel:
    </TextInput>
    <TextInput
      value={field.placeholder || ''}
      onChange={(e) => onChange({ ...field, placeholder: e.target.value })}
    >
      Platzhalter:
    </TextInput>
  </>
);

export default Email;
