import { Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { AllNewsQuery } from '../../../graphql/news/generated/allNewsQuery.generated';

const NewsColumn: FC<{ item: AllNewsQuery['allNews']['docs'][number] }> = ({ item }) => (
  <>
    <Td>{item.title}</Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default NewsColumn;
