import React, { FC } from 'react';
import { IMultiLineBlock } from '../../../models/forms';
import TextInput from '../../TextInput';

interface MultiLineProps {
  field: IMultiLineBlock,
  onChange: (value: IMultiLineBlock) => void,
}

const MultiLine: FC<MultiLineProps> = ({ field, onChange }) => (
  <>
    <TextInput
      value={field.label || ''}
      onChange={(e) => onChange({ ...field, label: e.target.value })}
    >
      Titel:
    </TextInput>
    <TextInput
      value={field.placeholder || ''}
      onChange={(e) => onChange({ ...field, placeholder: e.target.value })}
    >
      Platzhalter:
    </TextInput>
  </>
);

export default MultiLine;
