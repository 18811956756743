import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { NewsFragmentDoc } from '../../fragments/generated/news.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteNewsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type DeleteNewsMutation = { __typename?: 'Mutation', deleteNews: { __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string } };


export const DeleteNewsDocument = gql`
    mutation DeleteNews($id: ID!) {
  deleteNews(id: $id) {
    ...news
  }
}
    ${NewsFragmentDoc}`;
export type DeleteNewsMutationFn = Apollo.MutationFunction<DeleteNewsMutation, DeleteNewsMutationVariables>;

/**
 * __useDeleteNewsMutation__
 *
 * To run a mutation, you first call `useDeleteNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNewsMutation, { data, loading, error }] = useDeleteNewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNewsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNewsMutation, DeleteNewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNewsMutation, DeleteNewsMutationVariables>(DeleteNewsDocument, options);
      }
export type DeleteNewsMutationHookResult = ReturnType<typeof useDeleteNewsMutation>;
export type DeleteNewsMutationResult = Apollo.MutationResult<DeleteNewsMutation>;
export type DeleteNewsMutationOptions = Apollo.BaseMutationOptions<DeleteNewsMutation, DeleteNewsMutationVariables>;