import {
  Button, FormControl, FormLabel, Heading, Switch, useToast,
} from '@chakra-ui/react';
import { pick } from 'lodash';
import React, { ChangeEvent, FC } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../components/Layout';
import PlaceForm from '../../components/places/PlaceForm';
import { CreatePlaceMutationVariables } from '../../graphql/places/generated/createPlaceMutation.generated';
import { usePlaceQuery } from '../../graphql/places/generated/placeQuery.generated';
import { useUpdatePlaceMutation } from '../../graphql/places/generated/updatePlaceMutation.generated';
import { StatusTypes } from '../../types/graphql.generated';

const UpdatePlace: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams() as { id: string };

  const [updatePlace, { loading }] = useUpdatePlaceMutation();

  const { data: placeResponse } = usePlaceQuery({
    variables: {
      id,
    },
  });

  const onSubmit = async (data: CreatePlaceMutationVariables) => {
    try {
      await updatePlace({
        variables: {
          id,
          ...data,
        },
      });
      toast({
        title: 'Standort geändert',
        description: 'der Standort wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/places');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onStateChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      await updatePlace({
        variables: {
          id,
          status: e.target.checked ? StatusTypes.PUBLISHED : StatusTypes.DRAFT,
        },
      });
      toast({
        title: 'Standort geändert',
        description: 'der Standort wurde geändert',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
    } catch (error) {
      if (error instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${error.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Standort ändern</Heading>
      <Button
        as={Link}
        to="/places"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      {placeResponse && (
        <>
          <FormControl display="flex" alignItems="center" my="20px">
            <FormLabel htmlFor="state" mb="0">
              Veröffentlicht
            </FormLabel>
            <Switch id="state" onChange={onStateChange} isChecked={placeResponse.place.status === StatusTypes.PUBLISHED} />
          </FormControl>
          <PlaceForm
            loading={loading}
            onSubmit={onSubmit}
            defaultValues={{
              ...placeResponse.place,
              coordinates: pick(placeResponse.place.coordinates, ['lat', 'lng', 'name']),
            } as any}
          />
        </>
      )}
    </Layout>
  );
};

export default UpdatePlace;
