import { Badge, Image, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Artist } from '../../../types/graphql.generated';

const ArtistColumn: FC<{ item: Artist }> = ({ item }) => (
  <>
    <Td>
      {item.image && (
        <Image
          src={item.image.formats.xs.url}
          alt={item.image.altText ? item.image.altText : undefined}
        />
      )}
    </Td>
    <Td>{item.name}</Td>
    <Td>
      <Badge
        colorScheme={item.status === 'PUBLISHED' ? 'green' : 'yellow'}
      >
        {item.status === 'PUBLISHED' ? 'Veröffentlicht' : 'Entwurf'}
      </Badge>
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default ArtistColumn;
