import { Badge, Image, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { MainEventsQuery } from '../../../graphql/mainEvents/generated/mainEvents.generated';

const MainEventColumn: FC<{ item: MainEventsQuery['mainEvents']['docs'][number] }> = ({ item }) => (
  <>

    <Td>
      {item.logo && (
        <Image
          src={item.logo.formats.xs.url}
          alt={item.logo.altText ? item.logo.altText : undefined}
        />
      )}
    </Td>
    <Td>{item.name}</Td>
    <Td>
      <Badge
        colorScheme={item.status === 'PUBLISHED' ? 'green' : 'yellow'}
      >
        {item.status === 'PUBLISHED' ? 'Veröffentlicht' : 'Entwurf'}
      </Badge>
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default MainEventColumn;
