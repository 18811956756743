import React, { FC } from 'react';
import {
  Headline, Asset, Map, Paragraph, FAQ,
} from './blocks';
import Parking from './blocks/Parking';
import {
  Email, MultiLine, Number, Phone, SingleLine,
} from './forms';
import { BlockInputItems } from '../../models';

interface FieldRendererProps {
  field: BlockInputItems,
  onChange: (field: BlockInputItems) => void,
  locale?: string,
}

const FieldRenderer: FC<FieldRendererProps> = ({ field, onChange, locale }) => {
  switch (field.type) {
    case 'headline':
      return <Headline field={field} onChange={onChange} />;
    case 'paragraph':
      return <Paragraph field={field} onChange={onChange} />;
    case 'asset':
      return <Asset field={field} onChange={onChange} locale={locale} />;
    case 'map':
      return <Map field={field} onChange={onChange} />;
    case 'faq':
      return <FAQ field={field} onChange={onChange} />;
    case 'parking':
      return <Parking field={field} />;
    case 'single-line':
      return <SingleLine field={field} onChange={onChange} />;
    case 'multi-line':
      return <MultiLine field={field} onChange={onChange} />;
    case 'phone':
      return <Phone field={field} onChange={onChange} />;
    case 'email':
      return <Email field={field} onChange={onChange} />;
    case 'number':
      return <Number field={field} onChange={onChange} />;
    default:
      return null;
  }
};

export default FieldRenderer;
