import {
  Icon, IconButton, useDisclosure,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { IoMdTrash } from 'react-icons/io';
import { TableData } from '../../models/table';
import AlertDialogWrapper from '../AlertDialogWrapper';

interface DeleteButtonProps {
  item: TableData,
  onDelete: (id: string) => void,
}

const DeleteButton: FC<DeleteButtonProps> = ({ item, onDelete }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        colorScheme="teal"
        size="sm"
        onClick={onOpen}
        aria-label="Zeile löschen"
        icon={<Icon as={IoMdTrash} />}
      />
      <AlertDialogWrapper
        isOpen={isOpen}
        onClose={onClose}
        onAccept={() => onDelete(item.id)}
        title="Löschen?"
        description="Wollen sie die ausgewählte Ressource wirklich löschen?"
        buttonText="Löschen"
      />
    </>
  );
};

export default DeleteButton;
