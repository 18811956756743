import React, {
  FC, ReactNode, useEffect, useState,
} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
} from '@chakra-ui/react';
import { GoSearch, GoPlus } from 'react-icons/go';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import { useDebouncedValue } from 'rooks';
import { Link } from 'react-router-dom';
import TextInput from '../TextInput';
import { TableData, VariableTypes } from '../../models/table';
import InnerTable from './InnerTable';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

interface DefaultTableProps {
  items?: TableData[],
  variables: VariableTypes,
  pathPrefix: string,
  onVariablesChange: (params: Partial<VariableTypes>) => void,
  loading: boolean,
  totalPages?: number,
  page?: number,
  tableHead: ReactNode,
  onFilterClear?: () => void,
  additionalFilter?: ReactNode,
  onCopy?: (id: string, locale: string) => void,
  onDelete: (id: string) => void,
  onNotification?: (id: string) => void,
}

const DefaultTable: FC<DefaultTableProps> = ({
  variables,
  onVariablesChange,
  items,
  loading,
  totalPages,
  page,
  pathPrefix,
  tableHead,
  onCopy,
  onDelete,
  additionalFilter,
  onNotification,
  onFilterClear,
}) => {
  const [searchString, setSearchString] = useState<string>(variables.term);

  const [pageScope, setPageScope] = useState<{
    limit: number,
    offset: number,
  }>({
    limit: 3,
    offset: 0,
  });

  const [debouncedSearchString] = useDebouncedValue(searchString, 300);

  useEffect(() => {
    if (debouncedSearchString !== null) {
      onVariablesChange({ term: debouncedSearchString });
    }
  }, [debouncedSearchString]);

  const goToPage = (newPage: number) => {
    onVariablesChange({
      offset: newPage * LIMIT,
    });
  };

  const onOffsetChange = (newOffset: number) => {
    setPageScope({
      ...pageScope,
      offset: newOffset,
    });
  };

  return (
    <>
      <Flex
        w="full"
        justify="end"
      >
        <ButtonGroup>
          <Button
            onClick={() => onVariablesChange({ locale: 'de' })}
            isActive={variables.locale === 'de'}
          >
            DE
          </Button>
          <Button
            onClick={() => onVariablesChange({ locale: 'en' })}
            isActive={variables.locale === 'en'}
          >
            EN
          </Button>
        </ButtonGroup>
      </Flex>
      <Flex
        w="full"
        justify="space-between"
        align="end"
        mt="6"
      >

        <Flex gap="2" alignItems="end">
          <Box
            w="230px"
          >
            <TextInput
              placeholder="Suche..."
              type="text"
              onChange={(e) => setSearchString(e.target.value)}
              value={searchString}
              icon={<Icon as={GoSearch} />}
            >
              Suche:
            </TextInput>
          </Box>
          {additionalFilter}
        </Flex>
        <Flex gap="2">
          <Button
            leftIcon={<IoMdTrash />}
            colorScheme="red"
            onClick={onFilterClear}
          >
            Filter löschen
          </Button>
          <Button
            as={Link}
            to={`/${pathPrefix}/create?defaultLocale=${variables.locale}`}
            leftIcon={<GoPlus />}
            colorScheme="teal"
          >
            Erstellen
          </Button>
        </Flex>

      </Flex>
      <InnerTable
        loading={loading}
        items={items}
        onDelete={onDelete}
        onCopy={onCopy}
        pathPrefix={pathPrefix}
        tableHead={tableHead}
        onNotification={onNotification}
      />
      {(!loading) && (
        <Flex
          mt="20px"
          justify="end"
        >
          <ButtonGroup isAttached size="sm" variant="outline">
            {pageScope.offset > 0 && (
              <IconButton
                onClick={() => onOffsetChange(pageScope.offset - 1)}
                aria-label="Vorherige Seite"
                icon={<MdNavigateBefore />}
              />
            )}
            {Array(totalPages).fill('').map((_, index) => {
              if (
                index < pageScope.offset
                || index >= pageScope.offset + pageScope.limit
              ) return null;

              return (
                <Button
                  isActive={page === index + 1}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  onClick={() => goToPage(index)}
                >
                  {index + 1}
                </Button>
              );
            })}
            {totalPages && pageScope.offset < totalPages - pageScope.limit && (
              <IconButton
                onClick={() => onOffsetChange(pageScope.offset + 1)}
                aria-label="Nächste Seite"
                icon={<MdNavigateNext />}
              />
            )}
          </ButtonGroup>
        </Flex>
      )}
    </>
  );
};

export default DefaultTable;
