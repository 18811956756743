import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MainEventFragmentDoc } from '../../fragments/generated/main-event.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MainEventsQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  locale: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;


export type MainEventsQuery = { __typename?: 'Query', mainEvents: { __typename?: 'MainEventPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, content?: string | null, showScheduleLink?: boolean | null, position?: number | null, disableInfoAndProgram?: boolean | null, mainPageId?: string | null, createdAt: string, updatedAt: string, logo?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null }> } };


export const MainEventsDocument = gql`
    query MainEvents($term: String!, $status: StatusTypes, $locale: String!, $limit: Int!, $offset: Int!) {
  mainEvents(
    term: $term
    status: $status
    locale: $locale
    limit: $limit
    offset: $offset
  ) {
    docs {
      ...mainEvent
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    ${MainEventFragmentDoc}`;

/**
 * __useMainEventsQuery__
 *
 * To run a query within a React component, call `useMainEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMainEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMainEventsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      status: // value for 'status'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMainEventsQuery(baseOptions: Apollo.QueryHookOptions<MainEventsQuery, MainEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MainEventsQuery, MainEventsQueryVariables>(MainEventsDocument, options);
      }
export function useMainEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MainEventsQuery, MainEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MainEventsQuery, MainEventsQueryVariables>(MainEventsDocument, options);
        }
export type MainEventsQueryHookResult = ReturnType<typeof useMainEventsQuery>;
export type MainEventsLazyQueryHookResult = ReturnType<typeof useMainEventsLazyQuery>;
export type MainEventsQueryResult = Apollo.QueryResult<MainEventsQuery, MainEventsQueryVariables>;