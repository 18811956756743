import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type PlaceFragment = { __typename?: 'Place', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null, coordinates?: { __typename?: 'Coordinate', lat?: number | null, lng?: number | null, name?: string | null } | null };

export const PlaceFragmentDoc = gql`
    fragment place on Place {
  id
  name
  image {
    id
    name
    locale
    altText
    caption
    originalUrl
    type
    mimetype
    formats {
      xs {
        url
        width
      }
      s {
        url
        width
      }
      m {
        url
        width
      }
      l {
        url
        width
      }
      xl {
        url
        width
      }
    }
    createdAt
    updatedAt
  }
  status
  locale
  coordinates {
    lat
    lng
    name
  }
  description
  createdAt
  updatedAt
}
    `;