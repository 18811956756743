export type AssetTypes = 'VIDEO' | 'IMAGE';

const types: {
  [key in AssetTypes]: string[]
} = {
  VIDEO: ['video/', 'application/mp4', 'application/x—mpegurl', 'application/vnd.apple.mpegurl'],
  IMAGE: ['image/x—png', 'image/png', 'image/gif', 'image/jpeg', 'image/svg+xml', 'image/webp'],
};

export const getAssetType = (mimeType: string): AssetTypes | null => {
  for (const [type, mimeTypes] of Object.entries(types)) {
    for (const value of mimeTypes) {
      if (mimeType.startsWith(value)) return type as AssetTypes;
    }
  }

  return null;
};
