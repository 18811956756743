import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Th, useToast,
} from '@chakra-ui/react';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { MediasQueryVariables, useMediasQuery } from '../../graphql/media/generated/mediasQuery.generated';
import { useDeleteMediaMutation } from '../../graphql/media/generated/deleteMediaMutation.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('mediaVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const Medias: FC = () => {
  const toast = useToast();
  const [variables, setVariables] = useState<MediasQueryVariables>(getDefaultVariables());

  const onVariablesChange = (params: Partial<MediasQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const [deleteMedia] = useDeleteMediaMutation();

  const { data, loading, refetch } = useMediasQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    localStorage.setItem('mediaVariables', JSON.stringify(variables));
  }, [variables]);

  const onDelete = async (id: string) => {
    try {
      await deleteMedia({
        variables: {
          id,
        },
      });
      toast({
        title: 'Bild/Video gelöscht',
        description: 'Die ausgewählte Ressource wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('mediaVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.medias.docs as any}
        pathPrefix="media"
        page={data?.medias.page}
        totalPages={data?.medias.totalPages}
        onDelete={onDelete}
        loading={loading}
        onFilterClear={clearFilter}
        tableHead={(
          <>
            <Th>Bild</Th>
            <Th>Titel</Th>
            <Th>Beschreibung</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default Medias;
