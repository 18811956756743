import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type ArtistFragment = { __typename?: 'Artist', id: string, name: string, description?: string | null, createdAt: string, locale: string, updatedAt: string, status: Types.StatusTypes, image?: { __typename?: 'Media', id: string, name: string, locale: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', url: string, width: number }, s: { __typename?: 'MediaFormat', url: string, width: number }, m: { __typename?: 'MediaFormat', url: string, width: number }, l: { __typename?: 'MediaFormat', url: string, width: number }, xl: { __typename?: 'MediaFormat', url: string, width: number } } } | null };

export const ArtistFragmentDoc = gql`
    fragment artist on Artist {
  id
  name
  description
  createdAt
  locale
  updatedAt
  status
  image {
    id
    name
    locale
    altText
    caption
    originalUrl
    type
    mimetype
    formats {
      xs {
        url
        width
      }
      s {
        url
        width
      }
      m {
        url
        width
      }
      l {
        url
        width
      }
      xl {
        url
        width
      }
    }
    createdAt
    updatedAt
  }
}
    `;