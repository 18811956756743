import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc } from '../../fragments/generated/media.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MediaQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type MediaQuery = { __typename?: 'Query', media: { __typename?: 'Media', id: string, name: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, locale: string, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', width: number, url: string }, s: { __typename?: 'MediaFormat', width: number, url: string }, m: { __typename?: 'MediaFormat', width: number, url: string }, l: { __typename?: 'MediaFormat', width: number, url: string }, xl: { __typename?: 'MediaFormat', width: number, url: string } } } };


export const MediaDocument = gql`
    query Media($id: ID!) {
  media(id: $id) {
    ...media
  }
}
    ${MediaFragmentDoc}`;

/**
 * __useMediaQuery__
 *
 * To run a query within a React component, call `useMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMediaQuery(baseOptions: Apollo.QueryHookOptions<MediaQuery, MediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
      }
export function useMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MediaQuery, MediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MediaQuery, MediaQueryVariables>(MediaDocument, options);
        }
export type MediaQueryHookResult = ReturnType<typeof useMediaQuery>;
export type MediaLazyQueryHookResult = ReturnType<typeof useMediaLazyQuery>;
export type MediaQueryResult = Apollo.QueryResult<MediaQuery, MediaQueryVariables>;