import {
  Button, Flex,
} from '@chakra-ui/react';
import React, { BaseSyntheticEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { pick } from 'lodash';
import TextInput from '../TextInput';
import Richtext from '../Richtext';
import BlockInput from '../BlockInput';
import { StatusTypes } from '../../types/graphql.generated';
import { FormsQuery } from '../../graphql/forms/generated/formsQuery.generated';
import { CreateFormMutationVariables } from '../../graphql/forms/generated/createFormMutation.generated';

interface FormValues {
  name: string,
  description: string,
  content?: string,
}

interface FormsFormProps {
  locale: string,
  onSubmit: (data: CreateFormMutationVariables) => void,
  loading?: boolean,
  defaultValues?: FormsQuery['forms']['docs'][number] | null,
  withPublish?: boolean,
}

const FormsForm: FC<FormsFormProps> = ({
  locale,
  onSubmit,
  loading,
  defaultValues,
  withPublish = false,
}) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control,
  } = useForm<FormValues>({
    defaultValues: defaultValues ? pick(defaultValues, ['name', 'description', 'content']) : undefined,
  });

  const onSave = async (data: FormValues, e?: BaseSyntheticEvent) => {
    const buttonType = (e?.nativeEvent as any).submitter?.value || 'draft';

    await onSubmit({
      locale,
      content: data.content || '[]',
      ...(withPublish ? { status: buttonType === 'publish' ? StatusTypes.PUBLISHED : StatusTypes.DRAFT } : {}),
      ...data,
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <TextInput
          isInvalid={!!errors.name}
          {...register('name', { required: !defaultValues })}
          placeholder="Name..."
        >
          Name: *
        </TextInput>
        <Controller
          render={
            ({ field }) => (
              <Richtext
                isInvalid={!!errors.description}
                value={field.value}
                onChange={field.onChange}
              >
                Beschreibung: *
              </Richtext>
            )
          }
          rules={{ required: true }}
          control={control}
          name="description"
        />
        <Controller
          render={
            ({ field }) => (
              <BlockInput
                value={field.value}
                onChange={field.onChange}
                type="form"
                locale={locale}
              >
                Inhalt: *
              </BlockInput>
            )
          }
          rules={{ required: true }}
          control={control}
          name="content"
        />
      </Flex>
      <Flex gap={4}>
        <Button
          mt="30px"
          type="submit"
          value="draft"
          colorScheme="teal"
          isLoading={loading}
        >
          Speichern
        </Button>
        {withPublish && (
          <Button
            mt="30px"
            value="publish"
            type="submit"
            isLoading={loading}
          >
            Speichern & Veröffentlichen
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default FormsForm;
