import React, {
  ChangeEvent,
  FC, useEffect, useState,
} from 'react';
import {
  Box,
  Select,
  Th, useToast,
} from '@chakra-ui/react';
import { pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import { ArtistsQueryVariables, useArtistsQuery } from '../../graphql/artists/generated/artistsQuery.generated';
import DefaultTable from '../../components/table/DefaultTable';
import { useDeleteArtistMutation } from '../../graphql/artists/generated/deleteArtistMutation.generated';
import { useCreateArtistMutation } from '../../graphql/artists/generated/createArtistMutation.generated';
import { useMainEventsQuery } from '../../graphql/mainEvents/generated/mainEvents.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const pickValues = ['locale', 'name', 'description', 'image'];

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('artistsVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const Artists: FC = () => {
  const toast = useToast();
  const [variables, setVariables] = useState<ArtistsQueryVariables>(getDefaultVariables());
  const navigate = useNavigate();

  const [createArtist] = useCreateArtistMutation();

  const { data: mainEventData } = useMainEventsQuery({
    variables: {
      term: '',
      offset: 0,
      locale: variables.locale,
      limit: 100,
    },
    fetchPolicy: 'cache-and-network',
  });

  const onVariablesChange = (params: Partial<ArtistsQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  useEffect(() => {
    localStorage.setItem('artistsVariables', JSON.stringify(variables));
  }, [variables]);

  const { data, loading, refetch } = useArtistsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [deleteArtist] = useDeleteArtistMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteArtist({
        variables: {
          id,
        },
      });
      toast({
        title: 'Künstler:in wurde gelöscht',
        description: 'Die/der ausgewählte Künstler:in wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onCopy = async (artistId: string, locale: string) => {
    if (data && data.artists && data.artists.docs.length > 0) {
      const event = data.artists.docs.find((e) => e.id === artistId);
      if (event) {
        const parsedArtist = {
          ...pick(event, pickValues),
          image: event.image ? event.image.id : null,
        };
        const newArtist = await createArtist({
          variables: {
            ...parsedArtist as any,
            locale,
          },
        });
        toast({
          title: 'Künster:in erstellt',
          description: 'der/die Künster:in wurde erstellt',
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
        if (newArtist.data) {
          navigate(`/artists/${newArtist.data.createArtist.id}/update`);
        }
      }
    }
  };

  const onMainEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      onVariablesChange({
        mainEvent: e.target.value,
      });
    } else {
      onVariablesChange({
        mainEvent: undefined,
      });
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('artistsVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.artists.docs as any}
        pathPrefix="artists"
        page={data?.artists.page}
        totalPages={data?.artists.totalPages}
        loading={loading}
        onCopy={onCopy}
        onDelete={onDelete}
        onFilterClear={clearFilter}
        additionalFilter={(
          <Box>
            <Select
              onChange={onMainEventChange}
              placeholder="Alle Events"
              value={variables.mainEvent as any}
            >
              {mainEventData?.mainEvents.docs.map((mainEvent) => (
                <option key={mainEvent.id} value={mainEvent.id}>{mainEvent.name}</option>
              ))}
            </Select>
          </Box>
        )}
        tableHead={(
          <>
            <Th>Bild</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default Artists;
