import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { MediaFragmentDoc } from '../../fragments/generated/media.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateMediaMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  altText?: Types.InputMaybe<Types.Scalars['String']>;
  caption?: Types.InputMaybe<Types.Scalars['String']>;
  file?: Types.InputMaybe<Types.Scalars['Upload']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateMediaMutation = { __typename?: 'Mutation', updateMedia: { __typename?: 'Media', id: string, name: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, locale: string, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', width: number, url: string }, s: { __typename?: 'MediaFormat', width: number, url: string }, m: { __typename?: 'MediaFormat', width: number, url: string }, l: { __typename?: 'MediaFormat', width: number, url: string }, xl: { __typename?: 'MediaFormat', width: number, url: string } } } };


export const UpdateMediaDocument = gql`
    mutation UpdateMedia($id: ID!, $name: String, $altText: String, $caption: String, $file: Upload, $locale: String) {
  updateMedia(
    id: $id
    input: {name: $name, file: $file, locale: $locale, caption: $caption, altText: $altText}
  ) {
    ...media
  }
}
    ${MediaFragmentDoc}`;
export type UpdateMediaMutationFn = Apollo.MutationFunction<UpdateMediaMutation, UpdateMediaMutationVariables>;

/**
 * __useUpdateMediaMutation__
 *
 * To run a mutation, you first call `useUpdateMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMediaMutation, { data, loading, error }] = useUpdateMediaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      altText: // value for 'altText'
 *      caption: // value for 'caption'
 *      file: // value for 'file'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateMediaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMediaMutation, UpdateMediaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMediaMutation, UpdateMediaMutationVariables>(UpdateMediaDocument, options);
      }
export type UpdateMediaMutationHookResult = ReturnType<typeof useUpdateMediaMutation>;
export type UpdateMediaMutationResult = Apollo.MutationResult<UpdateMediaMutation>;
export type UpdateMediaMutationOptions = Apollo.BaseMutationOptions<UpdateMediaMutation, UpdateMediaMutationVariables>;