import { Button, Heading, useToast } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLeft } from 'react-icons/ai';
import Layout from '../../components/Layout';
import MediaForm, { MediaFormSubmit } from '../../components/media/MediaForm';
import { useCreateMediaMutation } from '../../graphql/media/generated/createMediaMutation.generated';

const CreateMedia: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createMedia, { loading }] = useCreateMediaMutation();

  const onSubmit = async (data: MediaFormSubmit) => {
    if (data.file.length === 0) return;
    try {
      await createMedia({
        variables: {
          ...data,
          file: data.file[0],
        },
      });
      toast({
        title: 'Bild/Video hochgeladen',
        description: 'Die ausgewählte Ressource wurde hochgeladen',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      navigate('/media');
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  return (
    <Layout>
      <Heading>Bilder oder video hochladen</Heading>
      <Button
        as={Link}
        to="/media"
        mt="4"
        leftIcon={<AiOutlineLeft />}
      >
        Zurück
      </Button>
      <MediaForm
        loading={loading}
        onSubmit={onSubmit}
      />
    </Layout>
  );
};

export default CreateMedia;
