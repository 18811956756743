import React from 'react';
import ReactDOM from 'react-dom';
import '../styles/global.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';
import '@aws-amplify/ui-react/styles.css';
import { Amplify, Auth, I18n } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import { translations, Authenticator, Flex } from '@aws-amplify/ui-react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import App from './App';
import generatedIntrospection from './types/fragments.generated';

const clientLink = createUploadLink({
  uri: process.env.GRAPHQL_ENDPOINT,
  credentials: 'include',
});

/* eslint-disable consistent-return */
const errorLink = onError(({
  networkError, operation, forward,
}) => {
  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    console.log(JSON.stringify(networkError));
    const [error] = (networkError as any).result.errors;
    if (error && error.message.startsWith('Context creation failed: expired')) {
      return new Observable((observer) => {
        Promise.all([
          Auth.currentAuthenticatedUser(),
          Auth.currentSession(),
        ]).then(([user, session]) => {
          user.refreshSession(session.getRefreshToken(), () => {
            console.log('refreshed token');
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          });
        }).catch((err) => {
          console.log(err);
          observer.error(err);
        });
      });
    }
  }
});
/* eslint-enable consistent-return */

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
  }),
  link: from([errorLink, clientLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'ignore',
    },
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});

Amplify.configure({
  Auth: {
    userPoolId: process.env.USER_POOL_ID,
    region: process.env.REGION,
    userPoolWebClientId: process.env.USER_POOL_CLIENT_ID,
    cookieStorage: {
      path: '/',
      expires: '',
      domain: process.env.COOKIE_ORIGIN || window.location.hostname,
      secure: process.env.REACT_APP_COOKIE_SECURE !== 'false',
    },
  },
});

I18n.putVocabularies(translations);
I18n.setLanguage('de');

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider
      client={client}
    >
      <BrowserRouter>
        <Flex
          alignItems="center"
          justifyContent="center"
          height="100vh"
        >
          <Authenticator
            hideSignUp
          >
            {() => (
              <Authenticator.Provider>
                <App />
              </Authenticator.Provider>
            )}
          </Authenticator>
        </Flex>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
