import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { PageFragmentDoc } from '../../fragments/generated/page.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PageQuery = { __typename?: 'Query', page: { __typename?: 'Page', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, order?: number | null, updatedAt: string } };


export const PageDocument = gql`
    query Page($id: ID!) {
  page(id: $id) {
    ...page
  }
}
    ${PageFragmentDoc}`;

/**
 * __usePageQuery__
 *
 * To run a query within a React component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options);
      }
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options);
        }
export type PageQueryHookResult = ReturnType<typeof usePageQuery>;
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>;
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>;