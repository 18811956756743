import React, { FC, useEffect, useState } from 'react';
import { useMediaLazyQuery } from '../../../graphql/media/generated/mediaQuery.generated';
import { IAssetBlock } from '../../../models/blocks';
import { Media } from '../../../types/graphql.generated';
import MediaInput from '../../MediaInput';
import TextInput from '../../TextInput';

interface AssetProps {
  field: IAssetBlock,
  onChange: (value: IAssetBlock) => void,
  locale?: string,
}

const Asset: FC<AssetProps> = ({ field, onChange, locale }) => {
  const [getMedia] = useMediaLazyQuery();

  const [selectedMedia, setSelectedMedia] = useState<Omit<Media, 'width' | 'height'>>();

  useEffect(() => {
    if (field.mediaId && !selectedMedia) {
      getMedia({
        variables: {
          id: field.mediaId,
        },
      }).then((response) => {
        if (response.data) {
          setSelectedMedia(response.data.media);
        }
      });
    }
  }, [field.id, selectedMedia]);

  useEffect(() => {
    if (selectedMedia) {
      onChange({ ...field, mediaId: selectedMedia.id });
    }
  }, [selectedMedia]);

  return (
    <>
      <TextInput
        mb="4"
        value={field.title || ''}
        onChange={(e) => onChange({ ...field, title: e.target.value })}
      >
        Titel:
      </TextInput>
      <MediaInput
        value={selectedMedia}
        locale={locale}
        isInvalid={false}
        onChange={(newMedia) => setSelectedMedia(newMedia)}
      >
        Video/Bild:
      </MediaInput>
    </>
  );
};

export default Asset;
