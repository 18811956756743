import { Badge, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { MainEventQuery } from '../../../graphql/mainEvents/generated/mainEventQuery.generated';

const PageColumn: FC<{ item: MainEventQuery['mainEvent']['pages'][number] }> = ({ item }) => (
  <>
    <Td>{item.name}</Td>
    <Td>
      <Badge
        colorScheme={item.status === 'PUBLISHED' ? 'green' : 'yellow'}
      >
        {item.status === 'PUBLISHED' ? 'Veröffentlicht' : 'Entwurf'}
      </Badge>
    </Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default PageColumn;
