import {
  Box,
  Button, Flex, Select,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GoPlus } from 'react-icons/go';
import { CallToSubscribeMutationVariables } from '../../graphql/notifications/generated/callToSubscribe.generated';
import RelationshipInput from '../RelationshipInput';
import { MainEventsDocument } from '../../graphql/mainEvents/generated/mainEvents.generated';
import { EventQuery } from '../../graphql/events/generated/eventQuery.generated';

interface FormValues {
  mainEvents: EventQuery['event']['mainEvent'][],
  locale: string,
}

interface PopupFormProps {
  onSubmit: (data: CallToSubscribeMutationVariables) => void,
  loading?: boolean,
}

const PopupForm: FC<PopupFormProps> = ({ onSubmit, loading }) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control, watch,
  } = useForm<FormValues>({
    defaultValues: {
      mainEvents: [],
      locale: 'de',
    },
  });

  const onSave = async (data: FormValues) => {
    const mainEvents = data.mainEvents.filter((element): element is NonNullable<EventQuery['event']['mainEvent']> => element !== null && element !== undefined);
    await onSubmit({
      ...data,
      mainEvents: mainEvents.map((event) => event.id),
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale')}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <Controller
          render={
            ({ field }) => (
              <Flex direction="column">
                {field.value.map((item, index) => (
                  <RelationshipInput
                    key={item ? item.id : `new-item-${index}`}
                    document={MainEventsDocument}
                    value={item as any}
                    onChange={(newItem) => {
                      field.onChange(field.value.map((oldItem, innerIndex) => {
                        if (index === innerIndex) return newItem;
                        return oldItem;
                      }));
                    }}
                    locale={watch('locale') || 'de'}
                  >
                    Event:
                  </RelationshipInput>
                ))}
                <Box mt="4">
                  <Button
                    colorScheme="teal"
                    leftIcon={<GoPlus />}
                    onClick={() => field.onChange([...field.value, null])}
                  >
                    Hinzufügen
                  </Button>
                </Box>
              </Flex>
            )
          }
          control={control}
          name="mainEvents"
        />
      </Flex>
      <Button
        mt="30px"
        type="submit"
        colorScheme="teal"
        isLoading={loading}
      >
        Senden
      </Button>
    </form>
  );
};

export default PopupForm;
