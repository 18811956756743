export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  JSON: string;
  URL: string;
  Upload: File;
};

export type Artist = {
  __typename?: 'Artist';
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  events: Array<Event>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  locale: Scalars['String'];
  name: Scalars['String'];
  status: StatusTypes;
  updatedAt: Scalars['DateTime'];
};

export type ArtistPaginateResult = {
  __typename?: 'ArtistPaginateResult';
  docs: Array<Artist>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type Coordinate = {
  __typename?: 'Coordinate';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type CoordinateInput = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CoordinateInputOptional = {
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CoordinateOptional = {
  __typename?: 'CoordinateOptional';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  artist?: Maybe<Artist>;
  coordinates?: Maybe<CoordinateOptional>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  form?: Maybe<Form>;
  fullDay?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  locale: Scalars['String'];
  location?: Maybe<Location>;
  mainEvent?: Maybe<MainEvent>;
  name: Scalars['String'];
  place?: Maybe<Place>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<StatusTypes>;
  subscribed?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
};


export type EventsubscribedArgs = {
  pushToken?: InputMaybe<Scalars['String']>;
};

export type EventPaginateResult = {
  __typename?: 'EventPaginateResult';
  docs: Array<Event>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type EventSubscription = {
  __typename?: 'EventSubscription';
  createdAt: Scalars['DateTime'];
  eventId: Scalars['ID'];
  id: Scalars['ID'];
  pushToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Form = {
  __typename?: 'Form';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  name: Scalars['String'];
  status: StatusTypes;
  updatedAt: Scalars['DateTime'];
};

export type FormPaginateResult = {
  __typename?: 'FormPaginateResult';
  docs: Array<Form>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type FormSubmission = {
  __typename?: 'FormSubmission';
  createdAt: Scalars['DateTime'];
  form: Form;
  id: Scalars['String'];
  payload: Scalars['JSON'];
  pushToken: Scalars['String'];
  status: SubmissionStatus;
  updatedAt: Scalars['DateTime'];
};

export type Formats = {
  __typename?: 'Formats';
  l: MediaFormat;
  m: MediaFormat;
  s: MediaFormat;
  xl: MediaFormat;
  xs: MediaFormat;
};

export type GeoPlace = {
  __typename?: 'GeoPlace';
  coordinates: Coordinate;
  name: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  city?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  information?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  city?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  information?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type MainEvent = {
  __typename?: 'MainEvent';
  content?: Maybe<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  disableInfoAndProgram?: Maybe<Scalars['Boolean']>;
  hasArtists: Scalars['Boolean'];
  hasPlaces: Scalars['Boolean'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  logo?: Maybe<Media>;
  mainPageId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pages: Array<Page>;
  position?: Maybe<Scalars['Int']>;
  showScheduleLink?: Maybe<Scalars['Boolean']>;
  status: StatusTypes;
  updatedAt: Scalars['DateTime'];
};


export type MainEventpagesArgs = {
  status?: InputMaybe<StatusTypes>;
};

export type MainEventPaginateResult = {
  __typename?: 'MainEventPaginateResult';
  docs: Array<MainEvent>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type Media = {
  __typename?: 'Media';
  altText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  formats: Formats;
  height: Scalars['Int'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  mimetype: Scalars['String'];
  name: Scalars['String'];
  originalUrl: Scalars['URL'];
  type: MediaType;
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
};

export type MediaFormat = {
  __typename?: 'MediaFormat';
  url: Scalars['URL'];
  width: Scalars['Int'];
};

export type MediaPaginateResult = {
  __typename?: 'MediaPaginateResult';
  docs: Array<Media>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type Message = {
  __typename?: 'Message';
  message: Scalars['String'];
};

export type MessageInput = {
  body: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  callToSubscribe: Notification;
  copyMainEvent: MainEvent;
  createArtist: Artist;
  createEvent: Event;
  createForm: Form;
  createMainEvent: MainEvent;
  createMedia: Media;
  createNews: News;
  createNotification: Notification;
  createPage: Page;
  createPlace: Place;
  deleteArtist: Artist;
  deleteEvent: Event;
  deleteForm: Form;
  deleteMainEvent: MainEvent;
  deleteMedia: Media;
  deleteNews: News;
  deletePage: Page;
  deletePlace: Place;
  deleteScheduledNotification: Notification;
  muteEvent?: Maybe<WatchEvent>;
  removeSubscriptions: Message;
  sendForm: FormSubmission;
  subscribeEvent: EventSubscription;
  unsubscribeEvent?: Maybe<EventSubscription>;
  updateArtist: Artist;
  updateEvent: Event;
  updateForm: Form;
  updateFormSubmissionStatus: FormSubmission;
  updateMainEvent: MainEvent;
  updateMedia: Media;
  updateNews: News;
  updatePage: Page;
  updatePlace: Place;
  updateScheduledNotification: Notification;
  updateSubscription: NotificationSubscription;
  watchEvent: WatchEvent;
};


export type MutationcallToSubscribeArgs = {
  locale: Scalars['String'];
  mainEvents: Array<Scalars['ID']>;
};


export type MutationcopyMainEventArgs = {
  id: Scalars['ID'];
  locale: Scalars['String'];
};


export type MutationcreateArtistArgs = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<StatusTypes>;
};


export type MutationcreateEventArgs = {
  artist?: InputMaybe<Scalars['ID']>;
  coordinates?: InputMaybe<CoordinateInputOptional>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  form?: InputMaybe<Scalars['ID']>;
  fullDay?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  mainEvent?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  place?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<StatusTypes>;
};


export type MutationcreateFormArgs = {
  content?: InputMaybe<Scalars['JSON']>;
  description: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<StatusTypes>;
};


export type MutationcreateMainEventArgs = {
  content?: InputMaybe<Scalars['JSON']>;
  description: Scalars['String'];
  disableInfoAndProgram?: InputMaybe<Scalars['Boolean']>;
  locale: Scalars['String'];
  logo?: InputMaybe<Scalars['ID']>;
  mainPageId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  position?: InputMaybe<Scalars['Int']>;
  showScheduleLink?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<StatusTypes>;
};


export type MutationcreateMediaArgs = {
  altText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  locale: Scalars['String'];
  name: Scalars['String'];
};


export type MutationcreateNewsArgs = {
  description: Scalars['String'];
  locale: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};


export type MutationcreateNotificationArgs = {
  de?: InputMaybe<MessageInput>;
  en?: InputMaybe<MessageInput>;
  scheduleForDate?: InputMaybe<Scalars['DateTime']>;
};


export type MutationcreatePageArgs = {
  content?: InputMaybe<Scalars['JSON']>;
  description: Scalars['String'];
  event: Scalars['ID'];
  locale: Scalars['String'];
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StatusTypes>;
};


export type MutationcreatePlaceArgs = {
  coordinates?: InputMaybe<CoordinateInput>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  locale: Scalars['String'];
  name: Scalars['String'];
  status?: InputMaybe<StatusTypes>;
};


export type MutationdeleteArtistArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteFormArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteMainEventArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteMediaArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteNewsArgs = {
  id: Scalars['ID'];
};


export type MutationdeletePageArgs = {
  id: Scalars['ID'];
};


export type MutationdeletePlaceArgs = {
  id: Scalars['ID'];
};


export type MutationdeleteScheduledNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationmuteEventArgs = {
  mainEvent: Scalars['ID'];
  pushToken: Scalars['String'];
};


export type MutationremoveSubscriptionsArgs = {
  pushToken: Scalars['String'];
};


export type MutationsendFormArgs = {
  form: Scalars['ID'];
  payload?: InputMaybe<Scalars['JSON']>;
  pushToken: Scalars['String'];
};


export type MutationsubscribeEventArgs = {
  event: Scalars['ID'];
  pushToken: Scalars['String'];
};


export type MutationunsubscribeEventArgs = {
  event: Scalars['ID'];
  pushToken: Scalars['String'];
};


export type MutationupdateArtistArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateArtistInput>;
};


export type MutationupdateEventArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateEventInput>;
};


export type MutationupdateFormArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateFormInput>;
};


export type MutationupdateFormSubmissionStatusArgs = {
  id: Scalars['ID'];
  status: SubmissionStatus;
};


export type MutationupdateMainEventArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateMainEventInput>;
};


export type MutationupdateMediaArgs = {
  id: Scalars['ID'];
  input: UpdateMediaInput;
};


export type MutationupdateNewsArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateNewsInput>;
};


export type MutationupdatePageArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdatePageInput>;
};


export type MutationupdatePlaceArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdatePlaceInput>;
};


export type MutationupdateScheduledNotificationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateScheduledNotificationInput>;
};


export type MutationupdateSubscriptionArgs = {
  locale: Scalars['String'];
  pushToken: Scalars['String'];
};


export type MutationwatchEventArgs = {
  mainEvent: Scalars['ID'];
  pushToken: Scalars['String'];
};

export type News = {
  __typename?: 'News';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  mainEvent?: Maybe<MainEvent>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type NewsPaginateResult = {
  __typename?: 'NewsPaginateResult';
  docs: Array<News>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type NotifcationPaginateResult = {
  __typename?: 'NotifcationPaginateResult';
  docs: Array<Notification>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type Notification = {
  __typename?: 'Notification';
  createdAt: Scalars['DateTime'];
  de?: Maybe<NotificationMessage>;
  en?: Maybe<NotificationMessage>;
  failedMessages?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  numberOfMessages?: Maybe<Scalars['Int']>;
  successfulMessages?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type NotificationMessage = {
  __typename?: 'NotificationMessage';
  body?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  mainEvent?: Maybe<MainEvent>;
  mainEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type NotificationSubscription = {
  __typename?: 'NotificationSubscription';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  pushToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Page = {
  __typename?: 'Page';
  content: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  locale: Scalars['String'];
  mainEvent: MainEvent;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  status: StatusTypes;
  updatedAt: Scalars['DateTime'];
};

export type PagePaginateResult = {
  __typename?: 'PagePaginateResult';
  docs: Array<Page>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type Parking = {
  __typename?: 'Parking';
  currentlyOpen: Scalars['Boolean'];
  freeParking: Scalars['Int'];
  title: Scalars['String'];
  totalParking: Scalars['Int'];
};

export type Place = {
  __typename?: 'Place';
  city?: Maybe<Scalars['String']>;
  coordinates?: Maybe<Coordinate>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  events: Array<Event>;
  id: Scalars['ID'];
  image?: Maybe<Media>;
  locale: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  status: StatusTypes;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  website?: Maybe<Scalars['String']>;
};

export type PlacePaginateResult = {
  __typename?: 'PlacePaginateResult';
  docs: Array<Place>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  allNews: NewsPaginateResult;
  artist: Artist;
  artists: ArtistPaginateResult;
  event: Event;
  events: EventPaginateResult;
  form: Form;
  forms: FormPaginateResult;
  forwardGeocode: Array<GeoPlace>;
  mainEvent: MainEvent;
  mainEvents: MainEventPaginateResult;
  media: Media;
  medias: MediaPaginateResult;
  multipleMainEvents: Array<MainEvent>;
  multipleUpcomingEvents: Array<Event>;
  myNotifications: Array<Notification>;
  news: News;
  notifications: NotifcationPaginateResult;
  page: Page;
  pages: PagePaginateResult;
  parking?: Maybe<Array<Parking>>;
  place: Place;
  places: PlacePaginateResult;
  reverseGeocode?: Maybe<GeoPlace>;
  scheduledNotification: ScheduledNotification;
  scheduledNotifications: ScheduledNotifcationPaginateResult;
  watchedEvents: Array<MainEvent>;
};


export type QueryallNewsArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['ID']>;
  offset: Scalars['Int'];
  term?: InputMaybe<Scalars['String']>;
};


export type QueryartistArgs = {
  id: Scalars['ID'];
};


export type QueryartistsArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryeventArgs = {
  id: Scalars['ID'];
};


export type QueryeventsArgs = {
  artists?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from?: InputMaybe<Scalars['DateTime']>;
  limit: Scalars['Int'];
  locale: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['ID']>;
  offset: Scalars['Int'];
  places?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sort?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
  timeField?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['DateTime']>;
};


export type QueryformArgs = {
  id: Scalars['ID'];
};


export type QueryformsArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  offset: Scalars['Int'];
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryforwardGeocodeArgs = {
  text: Scalars['String'];
};


export type QuerymainEventArgs = {
  id: Scalars['ID'];
};


export type QuerymainEventsArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  offset: Scalars['Int'];
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
};


export type QuerymediaArgs = {
  id: Scalars['ID'];
};


export type QuerymediasArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  offset: Scalars['Int'];
  term?: InputMaybe<Scalars['String']>;
};


export type QuerymultipleMainEventsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerymultipleUpcomingEventsArgs = {
  mainEvent?: InputMaybe<Scalars['ID']>;
  pushToken: Scalars['String'];
};


export type QuerymyNotificationsArgs = {
  pushToken: Scalars['String'];
};


export type QuerynewsArgs = {
  id: Scalars['ID'];
};


export type QuerynotificationsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  term?: InputMaybe<Scalars['String']>;
};


export type QuerypageArgs = {
  id: Scalars['ID'];
};


export type QuerypagesArgs = {
  event?: InputMaybe<Scalars['ID']>;
  limit: Scalars['Int'];
  locale: Scalars['String'];
  offset: Scalars['Int'];
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryplaceArgs = {
  id: Scalars['ID'];
};


export type QueryplacesArgs = {
  limit: Scalars['Int'];
  locale: Scalars['String'];
  mainEvent?: InputMaybe<Scalars['String']>;
  offset: Scalars['Int'];
  status?: InputMaybe<StatusTypes>;
  term?: InputMaybe<Scalars['String']>;
};


export type QueryreverseGeocodeArgs = {
  coordinates: CoordinateInput;
};


export type QueryscheduledNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryscheduledNotificationsArgs = {
  hasIdentifier?: InputMaybe<Scalars['Boolean']>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  term?: InputMaybe<Scalars['String']>;
};


export type QuerywatchedEventsArgs = {
  pushToken: Scalars['String'];
};

export type ScheduledNotifcationPaginateResult = {
  __typename?: 'ScheduledNotifcationPaginateResult';
  docs: Array<ScheduledNotification>;
  nextPage?: Maybe<Scalars['Int']>;
  page: Scalars['Int'];
  prevPage?: Maybe<Scalars['Int']>;
  totalPages: Scalars['Int'];
};

export type ScheduledNotification = {
  __typename?: 'ScheduledNotification';
  createdAt: Scalars['DateTime'];
  date?: Maybe<Scalars['DateTime']>;
  de?: Maybe<NotificationMessage>;
  en?: Maybe<NotificationMessage>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export enum StatusTypes {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum SubmissionStatus {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING'
}

export type UpdateArtistInput = {
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdateEventInput = {
  artist?: InputMaybe<Scalars['ID']>;
  coordinates?: InputMaybe<CoordinateInputOptional>;
  description?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  form?: InputMaybe<Scalars['ID']>;
  fullDay?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<LocationInput>;
  mainEvent?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  place?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdateFormInput = {
  content?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdateMainEventInput = {
  content?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  disableInfoAndProgram?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['ID']>;
  mainPageId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  showScheduleLink?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdateMediaInput = {
  altText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  file?: InputMaybe<Scalars['Upload']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateNewsInput = {
  description?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  mainEvent?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdatePageInput = {
  content?: InputMaybe<Scalars['JSON']>;
  description?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdatePlaceInput = {
  coordinates?: InputMaybe<CoordinateInput>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<StatusTypes>;
};

export type UpdateScheduledNotificationInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  de?: InputMaybe<MessageInput>;
  en?: InputMaybe<MessageInput>;
};

export type WatchEvent = {
  __typename?: 'WatchEvent';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  mainEvent: Scalars['ID'];
  pushToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};
