import { Image, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { Media } from '../../../types/graphql.generated';

const MediaColumn: FC<{ item: Media }> = ({ item }) => (
  <>
    <Td>
      <Image
        src={item.formats.xs.url}
        alt={item.altText ? item.altText : undefined}
      />
    </Td>
    <Td>{item.name}</Td>
    <Td>{item.altText}</Td>
    <Td>{format(new Date(item.createdAt), 'd.M.yyyy HH:mm')}</Td>
    <Td>{format(new Date(item.updatedAt), 'd.M.yyyy HH:mm')}</Td>
  </>
);

export default MediaColumn;
