import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { NewsWithEventFragmentDoc } from '../../fragments/generated/news.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllNewsQueryVariables = Types.Exact<{
  term: Types.Scalars['String'];
  locale: Types.Scalars['String'];
  limit: Types.Scalars['Int'];
  offset: Types.Scalars['Int'];
}>;


export type AllNewsQuery = { __typename?: 'Query', allNews: { __typename?: 'NewsPaginateResult', totalPages: number, page: number, nextPage?: number | null, prevPage?: number | null, docs: Array<{ __typename?: 'News', id: string, title: string, description: string, locale: string, createdAt: string, updatedAt: string, mainEvent?: { __typename?: 'MainEvent', id: string, name: string, status: Types.StatusTypes, locale: string, description?: string | null, createdAt: string, updatedAt: string } | null }> } };


export const AllNewsDocument = gql`
    query AllNews($term: String!, $locale: String!, $limit: Int!, $offset: Int!) {
  allNews(term: $term, locale: $locale, limit: $limit, offset: $offset) {
    docs {
      ...newsWithEvent
    }
    totalPages
    page
    nextPage
    prevPage
  }
}
    ${NewsWithEventFragmentDoc}`;

/**
 * __useAllNewsQuery__
 *
 * To run a query within a React component, call `useAllNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      locale: // value for 'locale'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useAllNewsQuery(baseOptions: Apollo.QueryHookOptions<AllNewsQuery, AllNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllNewsQuery, AllNewsQueryVariables>(AllNewsDocument, options);
      }
export function useAllNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllNewsQuery, AllNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllNewsQuery, AllNewsQueryVariables>(AllNewsDocument, options);
        }
export type AllNewsQueryHookResult = ReturnType<typeof useAllNewsQuery>;
export type AllNewsLazyQueryHookResult = ReturnType<typeof useAllNewsLazyQuery>;
export type AllNewsQueryResult = Apollo.QueryResult<AllNewsQuery, AllNewsQueryVariables>;