import {
  Box, Button, Flex, Icon, IconButton, Spinner, Text, useOutsideClick,
} from '@chakra-ui/react';
import React, {
  FC, useRef, useState,
} from 'react';
import { GoSearch } from 'react-icons/go';
import { useDebouncedValue } from 'rooks';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client';
import { IoMdTrash } from 'react-icons/io';
import TextInput from './TextInput';
import { TableData } from '../models/table';

interface RelationshipInputProps {
  document: DocumentNode,
  onChange?: (item: TableData | null) => void,
  value?: TableData | null,
  isInvalid?: boolean,
  locale: string,
}

const RelationshipInput: FC<RelationshipInputProps> = ({
  children, isInvalid, onChange, value, document, locale,
}) => {
  const [term, setTerm] = useState<string>('');
  const outsideRef = useRef<HTMLDivElement>(null);
  const [debouncedTerm] = useDebouncedValue(term, 300);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  useOutsideClick({
    ref: outsideRef,
    handler: () => setIsFocused(false),
  });
  const { data, loading } = useQuery(document, {
    variables: {
      term: debouncedTerm || '',
      locale,
      offset: 0,
      limit: 10,
    },
  });

  const selectItem = (item: TableData) => {
    if (onChange) {
      onChange(item);
      setIsFocused(false);
      setTerm('');
    }
  };

  const removeSelection = () => {
    if (onChange) {
      onChange(null);
      setIsFocused(false);
      setTerm('');
    }
  };

  let items: TableData[] = [];
  if (data) {
    items = data[Object.keys(data)[0]].docs;
  }

  return (
    <Box
      ref={outsideRef}
      borderColor={isInvalid ? 'red.500' : 'transparent'}
      borderWidth="2px"
      borderRadius="lg"
      p={isInvalid ? '3' : '0'}
    >
      <Text
        w="full"
        fontWeight="bold"
        mb="8px"
      >
        {children}
      </Text>
      {value && (
        <Flex
          background="gray.100"
          p="4"
          my="4"
          borderRadius="md"
          alignItems="center"
        >
          <Text
            fontWeight="bold"
            fontSize="lg"
            mr="2"
          >
            {'name' in value ? value.name : (value as any).title}
          </Text>
          <IconButton
            onClick={removeSelection}
            aria-label="auswahl löschen"
            icon={<IoMdTrash />}
          />
        </Flex>
      )}

      <TextInput
        value={term}
        onFocus={() => setIsFocused(true)}
        onChange={(e) => setTerm(e.target.value)}
        icon={<Icon as={GoSearch} />}
      >
        Suche:
      </TextInput>
      {(isFocused && data) && (
        <Box
          boxShadow="md"
          borderRadius="md"
          p="4"
          overflow="hidden"
        >
          {(!loading && items.length > 0)
            && items.map((item) => (
              <Button
                key={'name' in item ? item.name : (item as any).title}
                variant="unstyled"
                isFullWidth
                textAlign="left"
                overflow="hidden"
                onClick={() => selectItem(item)}
              >
                {'name' in item ? item.name : (item as any).title}
              </Button>
            ))}
          {(!loading && items.length === 0) && (
            <Text
              fontSize="md"
              fontWeight="bold"
            >
              Nichts gefunden
            </Text>
          )}
          {loading && (
            <Flex
              w="100%"
              justify="center"
            >
              <Spinner
                thickness="2px"
                color="teal"
                emptyColor="gray.200"
                size="md"
              />
            </Flex>
          )}
        </Box>
      )}
    </Box>
  );
};

export default RelationshipInput;
