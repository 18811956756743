import React, {
  FC, useEffect, useState,
} from 'react';
import {
  Th, useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { FormsQueryVariables, useFormsQuery } from '../../graphql/forms/generated/formsQuery.generated';
import { useCreateFormMutation } from '../../graphql/forms/generated/createFormMutation.generated';
import { useDeleteFormMutation } from '../../graphql/forms/generated/deleteFormMutation.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('formsVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const Forms: FC = () => {
  const toast = useToast();
  const [variables, setVariables] = useState<FormsQueryVariables>(getDefaultVariables());

  const [createForm] = useCreateFormMutation();

  const navigate = useNavigate();

  const onVariablesChange = (params: Partial<FormsQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  useEffect(() => {
    localStorage.setItem('formsVariables', JSON.stringify(variables));
  }, [variables]);

  const { data, loading, refetch } = useFormsQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [deleteForm] = useDeleteFormMutation();

  const onDelete = async (id: string) => {
    try {
      await deleteForm({
        variables: {
          id,
        },
      });
      toast({
        title: 'Formular wurde gelöscht',
        description: 'Der ausgewählte Formular wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onCopy = async (formId: string, locale: string) => {
    if (data && data.forms && data.forms.docs.length > 0) {
      const form = data.forms.docs.find((e) => e.id === formId);
      if (form) {
        const newForm = await createForm({
          variables: {
            ...form as any,
            locale,
          },
        });
        toast({
          title: 'Formular erstellt',
          description: 'Das Formular wurde erstellt',
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
        if (newForm.data) {
          navigate(`/forms/${newForm.data.createForm.id}/update`);
        }
      }
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('formsVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.forms.docs as any}
        pathPrefix="forms"
        page={data?.forms.page}
        totalPages={data?.forms.totalPages}
        loading={loading}
        onDelete={onDelete}
        onCopy={onCopy}
        onFilterClear={clearFilter}
        tableHead={(
          <>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default Forms;
