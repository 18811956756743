import {
  Box, Button, Flex, Icon, IconButton, Image, Select, Text,
} from '@chakra-ui/react';
import React, { FC, MouseEvent, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FiFile } from 'react-icons/fi';
import { IoMdTrash } from 'react-icons/io';
import { pick } from 'lodash';
import FileUpload from '../FileUpload';
import TextInput from '../TextInput';
import { CreateMediaMutationVariables } from '../../graphql/media/generated/createMediaMutation.generated';
import { getAssetType } from '../../util/get-asset-type';
import { Media } from '../../types/graphql.generated';
import stopPropagate from '../../util/stop-propagate';

interface FormValues {
  name: string,
  file: FileList,
  locale: string,
  altText?: string | null,
  caption?: string | null,
}

export interface MediaFormSubmit extends Omit<CreateMediaMutationVariables, 'file'> {
  file: FileList,
}

interface MediaFormProps {
  onSubmit: (data: MediaFormSubmit) => void,
  loading?: boolean,
  defaultValues?: Media,
  locale?: string,
}

const MediaForm: FC<MediaFormProps> = ({
  onSubmit, loading, defaultValues, locale,
}) => {
  const values = {
    ...(defaultValues || {}),
    locale: defaultValues?.locale || locale,
  };
  const {
    register, handleSubmit, watch, resetField, formState: { errors }, reset,
  } = useForm<FormValues>({
    defaultValues: pick(values, ['name', 'locale', 'altText', 'caption']),
  });

  const onSave = async (data: FormValues) => {
    await onSubmit(data);
    reset();
  };

  const previewImage = useMemo(() => {
    const file = watch('file');
    if (file && file.length > 0) {
      const item = file[0];

      return {
        url: URL.createObjectURL(item),
        type: getAssetType(item.type),
      };
    }
    if (defaultValues) {
      return {
        url: defaultValues.formats.m.url,
        type: defaultValues.type,
      };
    }
    return null;
  }, [watch('file')]);

  const handleRemoveFile = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    resetField('file');
  };

  const getFileName = () => {
    if (watch('file') && watch('file').length > 0) {
      return watch('file')[0].name;
    }

    if (defaultValues) {
      return defaultValues.name;
    }

    return null;
  };

  return (
    <form onSubmit={stopPropagate(handleSubmit(onSave))}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale', { required: !defaultValues })}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <TextInput
          isInvalid={!!errors.name}
          {...register('name', { required: !defaultValues })}
          placeholder="Name..."
        >
          Name: *
        </TextInput>
        <TextInput
          {...register('caption')}
          placeholder="Unterschrift..."
        >
          Unterschrift:
        </TextInput>
        <TextInput
          {...register('altText')}
          placeholder="Beschreibung..."
        >
          Beschreibung:
        </TextInput>
        <Box>
          <FileUpload
            isInvalid={!!errors.file}
            register={register('file', { required: !defaultValues })}
          >
            {previewImage ? (
              <Flex
                flexDir="column"
                gap="10"
              >
                {previewImage.type === 'IMAGE' ? (
                  <Image
                    w="xs"
                    src={previewImage.url}
                  />
                ) : (
                  <video src={previewImage.url} controls />
                )}

                <Flex
                  align="center"
                  gap="2"
                >
                  <Text>
                    {getFileName()}
                  </Text>
                  <IconButton
                    aria-label="Bild entfernen"
                    variant="outline"
                    size="lg"
                    onClick={handleRemoveFile}
                    icon={<Icon as={IoMdTrash} />}
                  />
                </Flex>
              </Flex>

            ) : (
              <Button leftIcon={<Icon as={FiFile} />}>
                Datei auswählen
              </Button>
            )}

          </FileUpload>
        </Box>
      </Flex>
      <Button
        type="submit"
        colorScheme="teal"
        isLoading={loading}
      >
        Speichern
      </Button>
    </form>
  );
};

export default MediaForm;
