import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateNotificationMutationVariables = Types.Exact<{
  en?: Types.InputMaybe<Types.MessageInput>;
  de?: Types.InputMaybe<Types.MessageInput>;
  scheduleForDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename?: 'Notification', id: string, numberOfMessages?: number | null, successfulMessages?: number | null, failedMessages?: number | null, createdAt: string, updatedAt: string, en?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null } | null, de?: { __typename?: 'NotificationMessage', title?: string | null, body?: string | null } | null } };


export const CreateNotificationDocument = gql`
    mutation CreateNotification($en: MessageInput, $de: MessageInput, $scheduleForDate: DateTime) {
  createNotification(en: $en, de: $de, scheduleForDate: $scheduleForDate) {
    id
    numberOfMessages
    successfulMessages
    failedMessages
    en {
      title
      body
    }
    de {
      title
      body
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      en: // value for 'en'
 *      de: // value for 'de'
 *      scheduleForDate: // value for 'scheduleForDate'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;