import {
  Button, Flex, Select,
} from '@chakra-ui/react';
import React, { BaseSyntheticEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isNull, omitBy, pick } from 'lodash';
import TextInput from '../TextInput';
import { Media, Place, StatusTypes } from '../../types/graphql.generated';
import Richtext from '../Richtext';
import MediaInput from '../MediaInput';
import ICoordinates from '../../models/ICoordinates';
import { CreatePlaceMutationVariables } from '../../graphql/places/generated/createPlaceMutation.generated';
import CoordinatesInput from '../CoordinatesInput';

interface FormValues {
  name: string,
  description: string,
  locale: string,
  image: Media,
  coordinates: ICoordinates,
}

interface PlaceFormProps {
  onSubmit: (data: CreatePlaceMutationVariables) => void,
  loading?: boolean,
  defaultValues?: Place,
  withPublish?: boolean,
}

const PlaceForm: FC<PlaceFormProps> = ({
  onSubmit, loading, defaultValues, withPublish = false,
}) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control, watch,
  } = useForm<FormValues>({
    defaultValues: defaultValues ? pick(omitBy<Place>(defaultValues, isNull), ['locale', 'name', 'description', 'image', 'coordinates']) : undefined,
  });

  const onSave = async (data: FormValues, e?: BaseSyntheticEvent) => {
    const buttonType = (e?.nativeEvent as any).submitter?.value || 'draft';

    await onSubmit({
      ...data,
      ...(withPublish ? { status: buttonType === 'publish' ? StatusTypes.PUBLISHED : StatusTypes.DRAFT } : {}),
      image: data.image ? data.image.id : null,
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale', { required: !defaultValues })}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <TextInput
          isInvalid={!!errors.name}
          {...register('name', { required: !defaultValues })}
          placeholder="Name..."
        >
          Name: *
        </TextInput>
        <Controller
          render={
            ({ field }) => (
              <Richtext
                isInvalid={!!errors.description}
                value={field.value}
                onChange={field.onChange}
              >
                Beschreibung:
              </Richtext>
            )
          }
          control={control}
          name="description"
        />
        <Controller
          render={
            ({ field }) => (
              <MediaInput
                isInvalid={!!errors.image}
                value={field.value}
                locale={watch('locale')}
                onChange={field.onChange}
              >
                Bild:
              </MediaInput>
            )
          }
          control={control}
          name="image"
        />
        <Controller
          render={
            ({ field }) => (
              <CoordinatesInput
                isInvalid={!!errors.coordinates}
                withAdress
                value={field.value}
                onChange={field.onChange}
              >
                Ort:
              </CoordinatesInput>
            )
          }
          control={control}
          name="coordinates"
        />
      </Flex>
      <Flex gap={4}>
        <Button
          mt="30px"
          type="submit"
          value="draft"
          colorScheme="teal"
          isLoading={loading}
        >
          Speichern
        </Button>
        {withPublish && (
          <Button
            mt="30px"
            value="publish"
            type="submit"
            isLoading={loading}
          >
            Speichern & Veröffentlichen
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default PlaceForm;
