import React, {
  ChangeEvent,
  FC, useEffect, useState,
} from 'react';
import {
  Box,
  Select,
  Th, useToast,
} from '@chakra-ui/react';
import { omit, pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Layout';
import DefaultTable from '../../components/table/DefaultTable';
import { PlacesQueryVariables, usePlacesQuery } from '../../graphql/places/generated/placesQuery.generated';
import { useDeletePlaceMutation } from '../../graphql/places/generated/deletePlaceMutation.generated';
import { useCreatePlaceMutation } from '../../graphql/places/generated/createPlaceMutation.generated';
import { useMainEventsQuery } from '../../graphql/mainEvents/generated/mainEvents.generated';

const LIMIT = parseInt(process.env.TABLE_LIMIT as string, 10);

const pickValues = ['locale', 'name', 'description', 'image', 'coordinates'];

const defaultVariables = {
  term: '',
  locale: 'de',
  limit: LIMIT,
  offset: 0,
};

const getDefaultVariables = () => {
  const localCopy = localStorage.getItem('placesVariables');
  if (localCopy) {
    return JSON.parse(localCopy);
  }

  return defaultVariables;
};

const Places: FC = () => {
  const toast = useToast();
  const [variables, setVariables] = useState<PlacesQueryVariables>(getDefaultVariables());

  const { data: mainEventData } = useMainEventsQuery({
    variables: {
      term: '',
      offset: 0,
      locale: variables.locale,
      limit: 100,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [createPlace] = useCreatePlaceMutation();

  const navigate = useNavigate();

  const onVariablesChange = (params: Partial<PlacesQueryVariables>) => {
    setVariables((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  useEffect(() => {
    localStorage.setItem('placesVariables', JSON.stringify(variables));
  }, [variables]);

  const { data, loading, refetch } = usePlacesQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [deletePlace] = useDeletePlaceMutation();

  const onDelete = async (id: string) => {
    try {
      await deletePlace({
        variables: {
          id,
        },
      });
      toast({
        title: 'Standort wurde gelöscht',
        description: 'Der ausgewählte Standort wurde gelöscht',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      refetch();
    } catch (e) {
      if (e instanceof Error) {
        toast({
          title: 'Es ist etwas schiefgelaufen',
          description: `Fehlermeldung: ${e.message}`,
          status: 'error',
          isClosable: true,
          duration: 5000,
        });
      }
    }
  };

  const onCopy = async (placeId: string, locale: string) => {
    if (data && data.places && data.places.docs.length > 0) {
      const place = data.places.docs.find((e) => e.id === placeId);
      if (place) {
        const parsedPlace = {
          ...pick(place, pickValues),
          image: place.image ? place.image.id : null,
          coordinates: omit(place.coordinates, ['__typename']),
        };
        const newPlace = await createPlace({
          variables: {
            ...parsedPlace as any,
            locale,
          },
        });
        toast({
          title: 'Standort erstellt',
          description: 'der Standort wurde erstellt',
          status: 'success',
          isClosable: true,
          duration: 5000,
        });
        if (newPlace.data) {
          navigate(`/places/${newPlace.data.createPlace.id}/update`);
        }
      }
    }
  };

  const onMainEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      onVariablesChange({
        mainEvent: e.target.value,
      });
    } else {
      onVariablesChange({
        mainEvent: undefined,
      });
    }
  };

  const clearFilter = () => {
    setVariables(defaultVariables);
    localStorage.removeItem('placesVariables');
    window.location.reload();
  };

  return (
    <Layout>
      <DefaultTable
        onVariablesChange={onVariablesChange}
        variables={variables}
        items={data?.places.docs as any}
        pathPrefix="places"
        page={data?.places.page}
        totalPages={data?.places.totalPages}
        loading={loading}
        onDelete={onDelete}
        onCopy={onCopy}
        onFilterClear={clearFilter}
        additionalFilter={(
          <Box>
            <Select
              onChange={onMainEventChange}
              placeholder="Alle Events"
              value={variables.mainEvent as any}
            >
              {mainEventData?.mainEvents.docs.map((mainEvent) => (
                <option
                  key={mainEvent.id}
                  value={mainEvent.id}
                >
                  {mainEvent.name}
                </option>
              ))}
            </Select>
          </Box>
        )}
        tableHead={(
          <>
            <Th>Bild</Th>
            <Th>Name</Th>
            <Th>Status</Th>
            <Th>Erstellt</Th>
            <Th>Geändert</Th>
          </>
        )}
      />
    </Layout>
  );
};

export default Places;
