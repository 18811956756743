import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
export type MediaFragment = { __typename?: 'Media', id: string, name: string, altText?: string | null, caption?: string | null, originalUrl: string, type: Types.MediaType, locale: string, mimetype: string, createdAt: string, updatedAt: string, formats: { __typename?: 'Formats', xs: { __typename?: 'MediaFormat', width: number, url: string }, s: { __typename?: 'MediaFormat', width: number, url: string }, m: { __typename?: 'MediaFormat', width: number, url: string }, l: { __typename?: 'MediaFormat', width: number, url: string }, xl: { __typename?: 'MediaFormat', width: number, url: string } } };

export const MediaFragmentDoc = gql`
    fragment media on Media {
  id
  name
  altText
  caption
  originalUrl
  type
  locale
  mimetype
  formats {
    xs {
      width
      url
    }
    s {
      width
      url
    }
    m {
      width
      url
    }
    l {
      width
      url
    }
    xl {
      width
      url
    }
  }
  createdAt
  updatedAt
}
    `;