import React, { FC, useRef } from 'react';
import { Box, InputGroup } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';

type FileUploadProps = {
  register: UseFormRegisterReturn,
  accept?: string,
  multiple?: boolean,
  isInvalid?: boolean,
};

const FileUpload: FC<FileUploadProps> = ({
  register, accept, multiple, children, isInvalid,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register as { ref: (instance: HTMLInputElement | null) => void };

  const handleClick = () => inputRef.current?.click();

  return (
    <Box
      borderColor={isInvalid ? 'red.500' : 'transparent'}
      borderWidth="2px"
      p="2"
      borderRadius="md"
    >
      <InputGroup onClick={handleClick}>
        <input
          type="file"
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e);
            inputRef.current = e;
          }}
        />
        <>
          {children}
        </>
      </InputGroup>
    </Box>
  );
};

export default FileUpload;
