import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { FormFragmentDoc } from '../../fragments/generated/form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFormMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  locale?: Types.InputMaybe<Types.Scalars['String']>;
  content?: Types.InputMaybe<Types.Scalars['JSON']>;
  status?: Types.InputMaybe<Types.StatusTypes>;
}>;


export type UpdateFormMutation = { __typename?: 'Mutation', updateForm: { __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string } };


export const UpdateFormDocument = gql`
    mutation UpdateForm($id: ID!, $name: String, $description: String, $locale: String, $content: JSON, $status: StatusTypes) {
  updateForm(
    id: $id
    input: {name: $name, description: $description, locale: $locale, status: $status, content: $content}
  ) {
    ...form
  }
}
    ${FormFragmentDoc}`;
export type UpdateFormMutationFn = Apollo.MutationFunction<UpdateFormMutation, UpdateFormMutationVariables>;

/**
 * __useUpdateFormMutation__
 *
 * To run a mutation, you first call `useUpdateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormMutation, { data, loading, error }] = useUpdateFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      content: // value for 'content'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormMutation, UpdateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormMutation, UpdateFormMutationVariables>(UpdateFormDocument, options);
      }
export type UpdateFormMutationHookResult = ReturnType<typeof useUpdateFormMutation>;
export type UpdateFormMutationResult = Apollo.MutationResult<UpdateFormMutation>;
export type UpdateFormMutationOptions = Apollo.BaseMutationOptions<UpdateFormMutation, UpdateFormMutationVariables>;