import React, { FC } from 'react';
import {
  Box, Flex, Heading, Icon, IconButton, useDisclosure,
} from '@chakra-ui/react';
import { IoMdTrash } from 'react-icons/io';
import AlertDialogWrapper from '../AlertDialogWrapper';
import { BlockInputItems } from '../../models';

interface FieldDecoratorProps {
  title: string,
  field: BlockInputItems,
  onDelete: (id: string) => void,
}

const FieldDecorator: FC<FieldDecoratorProps> = ({
  title, field, onDelete, children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      border="solid"
      borderColor="gray.300"
      p={4}
      borderRadius="md"
      my={4}
      backgroundColor="white"
    >
      <Flex
        justify="space-between"
      >
        <Heading
          size="lg"
          mb="4"
        >
          {title}
        </Heading>
        <Box>
          <IconButton
            colorScheme="teal"
            size="sm"
            onClick={onOpen}
            aria-label="Block löschen"
            icon={<Icon as={IoMdTrash} />}
          />
          <AlertDialogWrapper
            isOpen={isOpen}
            onClose={onClose}
            onAccept={() => onDelete(field.id)}
            title="Löschen?"
            description="Wollen sie den ausgewählten Block wirklich löschen?"
            buttonText="Löschen"
          />
        </Box>
      </Flex>
      {children}
    </Box>
  );
};

export default FieldDecorator;
