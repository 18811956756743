import * as Types from '../../../types/graphql.generated';

import { gql } from '@apollo/client';
import { FormFragmentDoc } from '../../fragments/generated/form.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFormMutationVariables = Types.Exact<{
  name: Types.Scalars['String'];
  description: Types.Scalars['String'];
  locale: Types.Scalars['String'];
  status?: Types.InputMaybe<Types.StatusTypes>;
  content: Types.Scalars['JSON'];
}>;


export type CreateFormMutation = { __typename?: 'Mutation', createForm: { __typename?: 'Form', id: string, name: string, status: Types.StatusTypes, locale: string, description: string, content: string, createdAt: string, updatedAt: string } };


export const CreateFormDocument = gql`
    mutation CreateForm($name: String!, $description: String!, $locale: String!, $status: StatusTypes, $content: JSON!) {
  createForm(
    name: $name
    description: $description
    locale: $locale
    status: $status
    content: $content
  ) {
    ...form
  }
}
    ${FormFragmentDoc}`;
export type CreateFormMutationFn = Apollo.MutationFunction<CreateFormMutation, CreateFormMutationVariables>;

/**
 * __useCreateFormMutation__
 *
 * To run a mutation, you first call `useCreateFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormMutation, { data, loading, error }] = useCreateFormMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      locale: // value for 'locale'
 *      status: // value for 'status'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormMutation, CreateFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormMutation, CreateFormMutationVariables>(CreateFormDocument, options);
      }
export type CreateFormMutationHookResult = ReturnType<typeof useCreateFormMutation>;
export type CreateFormMutationResult = Apollo.MutationResult<CreateFormMutation>;
export type CreateFormMutationOptions = Apollo.BaseMutationOptions<CreateFormMutation, CreateFormMutationVariables>;