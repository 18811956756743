import {
  Button, Checkbox, Flex, Select,
} from '@chakra-ui/react';
import React, { BaseSyntheticEvent, FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { isNull, omitBy, pick } from 'lodash';
import TextInput from '../TextInput';
import { Media, StatusTypes } from '../../types/graphql.generated';
import Richtext from '../Richtext';
import MediaInput from '../MediaInput';
import { CreateMainEventMutationVariables } from '../../graphql/mainEvents/generated/createMainEventMutation.generated';
import { MainEventQuery } from '../../graphql/mainEvents/generated/mainEventQuery.generated';
import BlockInput from '../BlockInput';

interface FormValues {
  name: string,
  description: string,
  locale: string,
  logo: Media,
  content?: any,
  mainPageId?: string,
  disableInfoAndProgram?: boolean,
  showScheduleLink?: boolean,
  position?: number,
}

interface MainEventFormProps {
  onSubmit: (data: CreateMainEventMutationVariables) => void,
  loading?: boolean,
  defaultValues?: MainEventQuery['mainEvent'],
  withPublish?: boolean,
}

const MainEventForm: FC<MainEventFormProps> = ({
  onSubmit, loading, defaultValues, withPublish = false,
}) => {
  const {
    register, handleSubmit, formState: { errors }, reset, control, watch,
  } = useForm<FormValues>({
    defaultValues: defaultValues ? pick(omitBy<MainEventQuery['mainEvent']>(defaultValues, isNull), ['locale', 'name', 'description', 'logo', 'content', 'mainPageId', 'showScheduleLink', 'disableInfoAndProgram', 'position']) : undefined,
  });

  const onSave = async (data: FormValues, e?: BaseSyntheticEvent) => {
    const buttonType = (e?.nativeEvent as any).submitter?.value || 'draft';

    await onSubmit({
      ...data,
      ...(withPublish ? { status: buttonType === 'publish' ? StatusTypes.PUBLISHED : StatusTypes.DRAFT } : {}),
      logo: data.logo?.id || null,
    });
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <Flex
        flexDir="column"
        gap="5"
        my="5"
        maxW="500px"
      >
        <Select
          isInvalid={!!errors.locale}
          {...register('locale', { required: !defaultValues })}
          placeholder="Sprache auswählen"
        >
          <option value="de">Deutsch</option>
          <option value="en">English</option>
        </Select>
        <TextInput
          isInvalid={!!errors.name}
          {...register('name', { required: !defaultValues })}
          placeholder="Name..."
        >
          Name: *
        </TextInput>
        <TextInput
          isInvalid={!!errors.position}
          {...register('position', { required: !defaultValues, valueAsNumber: true })}
          placeholder="Position..."
        >
          Position:
        </TextInput>
        <Controller
          render={
            ({ field }) => (
              <Richtext
                isInvalid={!!errors.description}
                value={field.value}
                onChange={field.onChange}
              >
                Beschreibung: *
              </Richtext>
            )
          }
          rules={{ required: true }}
          control={control}
          name="description"
        />
        <Controller
          render={
            ({ field }) => (
              <MediaInput
                isInvalid={!!errors.logo}
                value={field.value}
                locale={watch('locale')}
                onChange={field.onChange}
              >
                Logo:
              </MediaInput>
            )
          }
          control={control}
          name="logo"
        />
        <Checkbox
          {...register('showScheduleLink')}
        >
          Link zum Programm anzeigen
        </Checkbox>
        <Checkbox
          {...register('disableInfoAndProgram')}
        >
          Info und Programm ausblenden
        </Checkbox>
        {defaultValues?.pages.length && (
          <Select
            {...register('mainPageId')}
            placeholder="Nur folgende Seite anzeigen"
          >
            {defaultValues.pages.map((page) => (
              <option key={page.id} value={page.id}>{page.name}</option>
            ))}
          </Select>
        )}

        <Controller
          render={
            ({ field }) => (
              <BlockInput
                value={field.value}
                onChange={field.onChange}
                locale={watch('locale')}
              >
                Spezial Inhalt:
              </BlockInput>
            )
          }
          rules={{ required: true }}
          control={control}
          name="content"
        />
      </Flex>
      <Flex gap={4}>
        <Button
          mt="30px"
          type="submit"
          value="draft"
          colorScheme="teal"
          isLoading={loading}
        >
          Speichern
        </Button>
        {withPublish && (
          <Button
            mt="30px"
            value="publish"
            type="submit"
            isLoading={loading}
          >
            Speichern & Veröffentlichen
          </Button>
        )}
      </Flex>
    </form>
  );
};

export default MainEventForm;
